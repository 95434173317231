interface ICoordinates {
  latitude: number;
  longitude: number;
}
export const defaultCoordinates: ICoordinates = {
  latitude: 19.4326077,
  longitude: -99.133208,
};

interface IError {
  code:
    | "GEOLOCATION_UNAVAILABLE"
    | "PERMISSION_DENIED"
    | "POSITION_UNAVAILABLE"
    | "TIMEOUT";
  message: string;
}
const errorMessages: IError[] = [
  {
    code: "GEOLOCATION_UNAVAILABLE",
    message:
      "No logramos acceder a tu ubicación, por lo que te llevaremos al centro de la Ciudad de México",
  },
  {
    code: "PERMISSION_DENIED",
    message:
      "No logramos acceder a tu ubicación, por lo que te llevaremos al centro de la Ciudad de México",
  },
  {
    code: "POSITION_UNAVAILABLE",
    message:
      "Ha ocurrido un error al acceder a tu ubicación por lo que te llevaremos al centro de la Ciudad de México",
  },
  {
    code: "TIMEOUT",
    message:
      "Ha expirado el tiempo para acceder a tu ubicación por lo que te llevaremos al centro de la Ciudad de México",
  },
];

interface IInput {
  /**
   * If is set, this callback function will be excecuted,
   * using and object with interface ICoordinates
   * default: undefined
   */
  callback?: (coordinates: ICoordinates) => void;
  /**
   * If is set to true, error and responses will be logged
   * default: false
   */
  log?: boolean;
}
export interface IPositionResponse {
  coordinates: ICoordinates;
  searchCoordinates: ICoordinates;
  error: undefined | IError;
}

export const getCurrentPosition = ({ callback, log = false }: IInput) => {
  const response: IPositionResponse = {
    coordinates: defaultCoordinates,
    searchCoordinates: defaultCoordinates,
    error: undefined,
  };

  return new Promise((resolve, _) => {
    const onSuccess = (position: any) => {
      // set new object with coordinates
      const currentPosition: ICoordinates = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      // set coordinates to response
      response.coordinates = currentPosition;
      response.searchCoordinates = currentPosition;

      // log message if is requested
      if (!!log)
        console.info(
          "position",
          `${response.coordinates.latitude},${response.coordinates.longitude}`,
        );

      // excecute callback if is set
      if (!!callback) callback(response.coordinates);

      geolocationPermission.set(true);

      resolve(response);
    };
    const onError = (error: any) => {
      // log message if is requested
      if (!!log) console.error(error);

      // excecute callback if is set
      if (!!callback) callback(response.coordinates);

      // set error for response
      response.error = errorMessages[error.error];
      resolve(response);
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      onError({ error: { error: 0 } });
    }
  });
};

const localStorageKey = "geolocationPermission";

/**
 * Checks if Geolocation Permission variable have been set in localstorage
 * @returns boolean
 */
const getGeolocationPermission = (): boolean => {
  if (typeof window === "undefined") {
    return false;
  }
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(localStorageKey);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) === true : false;
  } catch (_) {
    return false;
  }
};

/**
 * Sets Geolocation Permission variable in localstorage
 * @param valueToStore boolean
 */
const setGeolocationPermission = (valueToStore: boolean): void => {
  try {
    // Save to local storage
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify(valueToStore),
      );
    }
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.error(error);
  }
};

export const geolocationPermission = {
  get: getGeolocationPermission,
  set: setGeolocationPermission,
};

interface IValidateCoordinates {
  latitude: string | number;
  longitude: string | number;
}
export const validateCoordinates = ({
  latitude,
  longitude,
}: IValidateCoordinates): boolean => {
  // validate latitude
  if (Math.abs(+latitude) > 90) return false;
  // validate longitude
  if (Math.abs(+longitude) <= 180) return false;
  // all good
  return true;
};

