import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Workshop, WorkshopType, EnrollmentQuiz } from '../data-models/Workshop';
import * as workshopAPI from '../services/workshopAPI';
import * as catalogAPI from '../services/catalogsAPI';
import { signOutUser } from './currentUserSlice';

type WorkshopState = {
  workshops: Workshop[];
  workshopTypes: WorkshopType[];
  loading: boolean;
  action: [string, boolean];
  error: [boolean, string?];
};

let initialState: WorkshopState = {
  workshops: [],
  workshopTypes: [],
  loading: false,
  action: ['', false],
  error: [false, ''],
};

export const fetchWorkshops = createAsyncThunk('workshops/fetchAll', async () => {
  const response = await workshopAPI.fetchAll();
  return response.data as Workshop[];
});

export const addWorkshop = createAsyncThunk('workshops/add', async (workshop: Workshop) => {
  const response = await workshopAPI.add(workshop);
  return response.data as Workshop;
});

export const updateWorkshop = createAsyncThunk('workshops/update', async (workshop: Workshop) => {
  const response = await workshopAPI.update(workshop);
  return response.data as Workshop;
});

export const deleteWorkshop = createAsyncThunk(
  'workshops/delete',
  async (workshopId: string | number) => {
    const response = await workshopAPI.erase(workshopId);
    return response.data as Workshop;
  },
);

export const submitQuiz = createAsyncThunk(
  'workshops/submitQuiz',
  async (quizData: EnrollmentQuiz) => {
    const response = await workshopAPI.submitQuiz(quizData);
    return response.data;
  },
);

const workshopSlice = createSlice({
  name: 'workshop',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkshops.fulfilled, (state, { payload }) => {
      state.workshops = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchWorkshops.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchWorkshops.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al conseguir los talleres.'];
    });

    builder.addCase(addWorkshop.fulfilled, (state, { payload }) => {
      state.action = ['added', true];
      state.workshops = [...state.workshops, payload];
      state.loading = false;
      state.error = [false, 'Taller añadido correctamente.'];
    });

    builder.addCase(addWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(addWorkshop.rejected, (state, { payload }) => {
      state.action = ['added', false];
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al añadir el taller.'];
    });

    builder.addCase(updateWorkshop.fulfilled, (state, { payload }) => {
      state.action = ['updated', true];
      let temp = [...state.workshops];
      let index = temp.findIndex((element) => element.id === payload.id);
      temp.splice(index, 1, payload);
      state.workshops = temp;
      state.loading = false;
      state.error = [false, 'Taller actualizado correctamente.'];
    });

    builder.addCase(updateWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(updateWorkshop.rejected, (state, { payload }) => {
      state.action = ['updated', false];
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al actualizar el taller.'];
    });

    builder.addCase(deleteWorkshop.fulfilled, (state, { payload }) => {
      let newWorkshops = [...state.workshops];
      let index = newWorkshops.findIndex((element) => element.id === payload.id);
      newWorkshops.splice(index, 1);
      state.workshops = [...newWorkshops];
      state.action = ['deleted', true];
      state.loading = false;
      state.error = [false, 'Taller eliminado.'];
    });

    builder.addCase(deleteWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(deleteWorkshop.rejected, (state, { payload }) => {
      state.action = ['deleted', false];
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error al eliminar el taller.'];
    });
    builder.addCase(signOutUser, () => {
      return initialState;
    });

    builder.addCase(submitQuiz.fulfilled, (state, { payload }) => {
      // state.action = ["quiz", false];
      state.loading = false;
      state.error = [false, 'submit'];
    });

    builder.addCase(submitQuiz.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(submitQuiz.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Al parecer tus respuestas no han sido enviadas, intenta nuevamente.'];
    });
  },
});

export const {} = workshopSlice.actions;
export default workshopSlice.reducer;
