import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

import "./assets/Fonts/em-print/styles.css";
import "antd/dist/antd.css";
import "./theme/variables.css";
import "./theme/styles.css";



import { Provider } from "react-redux";
import store from "./stores";

import { ConfigProvider } from "antd";

import "moment/locale/es-mx";
import locale from "antd/lib/locale/es_ES";
import { AppSuperUser } from "./AppSuperUser";

if (
  window.location.protocol !== "https:" &&
  !window.location.href.includes("localhost")
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  );
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <AppSuperUser />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
