import React, { FC, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Form,
  Modal,
  Input,
  Image,
  Dropdown,
  message,
} from "antd";
import { BlobServiceClient } from "@azure/storage-blob";
import { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { CkReloadIcon, CKCloseIcon } from "../../../../assets/SvgIcons";
import { BannersAPI } from "../../../../app/Services/axios";
import { getSasToken } from "../../../../stores/selectors";
import { ConfirmationModal } from "../../../../app/Components/Modals/ConfirmationModal";
import Upload from "antd/lib/upload/Upload";
import "./styles.css";

const containerName = "up-images-container";
const account = process.env.REACT_APP_STORAGEACCOUNT as string;
let blobService: any = null;
let containerClient: any = null;

const DIMENSIONSPROPORTIONMOBILE = 600 / 400; // width / height
const DIMENSIONSPROPORTIONDESKTOP = 1920 / 300; // width / height
const DEFAULTENTRYIMAGES = {
  bannerUrlImageDesktopWebp: "",
  bannerUrlImageMobileWebp: "",
  bannerUrlImageDesktop: "",
  bannerUrlImageMobile: "",
};

interface IProps {}

const BannersTable: FC<IProps> = () => {
  const sasToken = useSelector(getSasToken);
  const [bannerToDelete, setBannerToDelete] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editingBanner, setEditingBanner] = useState<boolean>(false);
  const [entryForm] = Form.useForm();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [newEntryImages, setNewEntryImages] = useState<any>({
    ...DEFAULTENTRYIMAGES,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const sharedUploadProps = {
    name: "file",
    showUploadList: false,
    accept: "image/png, image/jpeg, image/jpg",
  };

  const setFormField = (name: string, value: any) => {
    entryForm.setFieldsValue({
      [name]: value,
    });
    setNewEntryImages({
      ...newEntryImages,
      [name]: value,
    });
  };

  const uploadToContainer = async (
    onSuccess: any,
    onError: any,
    file: any,
    fieldName: string,
  ) => {
    let date = new Date();
    let fileName = `banners/${date.getTime()}_${file.name}`;
    try {
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.upload(file, file.size);
      setFormField(fieldName, blockBlobClient.url.split("?")[0]);
      message.success({
        content: `La imagen, ${file.name}, se subió correctamente.`,
      });
      onSuccess();
    } catch (error) {
      console.log(error);
      message.error({
        content: `Ocurrió un error al subir la imagen ${file.name}.`,
      });
      onError();
    }
  };

  const uploadImagePropsMobileWebp = {
    ...sharedUploadProps,
    beforeUpload: (file: any): Promise<boolean> =>
      new Promise((resolve) => {
        if (!file.type.startsWith("image/")) {
          message.error({
            content: `Solo se soportan archivos webp.`,
            style: { zIndex: 3000 },
          });
          resolve(false);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          const _loadedImageUrl = event?.target?.result;
          const image = document.createElement("img");
          image.src = _loadedImageUrl;
          image.addEventListener("load", () => {
            const { width, height } = image;

            if (width / height !== DIMENSIONSPROPORTIONMOBILE) {
              message.error({
                content: `La imagen no cuenta con las proporciones adecuadas para móvil.`,
                zIndex: 9999,
              });
              resolve(false);
            }
            resolve(true);
          });
        });
      }),
    customRequest: async ({ onSuccess, onError, file }: any) => {
      await uploadToContainer(
        onSuccess,
        onError,
        file,
        "bannerUrlImageMobileWebp",
      );
    },
  };

  const uploadImagePropsDesktopWebp = {
    ...sharedUploadProps,
    beforeUpload: (file: any): Promise<boolean> =>
      new Promise((resolve) => {
        if (!file.type.startsWith("image/")) {
          message.error({
            content: `Solo se soportan archivos png, jpg, jpeg.`,
            style: { zIndex: 3000 },
          });
          resolve(false);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          const _loadedImageUrl = event?.target?.result;
          const image = document.createElement("img");
          image.src = _loadedImageUrl;
          image.addEventListener("load", () => {
            const { width, height } = image;

            if (width / height !== DIMENSIONSPROPORTIONDESKTOP) {
              message.error({
                content: `La imagen no cuenta con las proporciones adecuadas para escritorio.`,
                zIndex: 9999,
              });
              resolve(false);
            }
            resolve(true);
          });
        });
      }),
    customRequest: async ({ onSuccess, onError, file }: any) => {
      await uploadToContainer(
        onSuccess,
        onError,
        file,
        "bannerUrlImageDesktopWebp",
      );
    },
  };

  const uploadImagePropsMobile = {
    ...sharedUploadProps,
    beforeUpload: (file: any): Promise<boolean> =>
      new Promise((resolve) => {
        if (!file.type.startsWith("image/")) {
          message.error({
            content: `Solo se soportan archivos png, jpg, jpeg.`,
            style: { zIndex: 3000 },
          });
          resolve(false);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          const _loadedImageUrl = event?.target?.result;
          const image = document.createElement("img");
          image.src = _loadedImageUrl;
          image.addEventListener("load", () => {
            const { width, height } = image;

            if (width / height !== DIMENSIONSPROPORTIONMOBILE) {
              message.error({
                content: `La imagen no cuenta con las proporciones adecuadas para móvil.`,
                zIndex: 9999,
              });
              resolve(false);
            }
            resolve(true);
          });
        });
      }),
    customRequest: async ({ onSuccess, onError, file }: any) => {
      await uploadToContainer(onSuccess, onError, file, "bannerUrlImageMobile");
    },
  };

  const uploadImagePropsDesktop = {
    ...sharedUploadProps,
    beforeUpload: (file: any): Promise<boolean> =>
      new Promise((resolve) => {
        if (!file.type.startsWith("image/")) {
          message.error({
            content: `Solo se soportan archivos png, jpg, jpeg.`,
            style: { zIndex: 3000 },
          });
          resolve(false);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", (event) => {
          const _loadedImageUrl = event?.target?.result;
          const image = document.createElement("img");
          image.src = _loadedImageUrl;
          image.addEventListener("load", () => {
            const { width, height } = image;

            if (width / height !== DIMENSIONSPROPORTIONDESKTOP) {
              message.error({
                content: `La imagen no cuenta con las proporciones adecuadas para escritorio.`,
                zIndex: 9999,
              });
              resolve(false);
            }
            resolve(true);
          });
        });
      }),
    customRequest: async ({ onSuccess, onError, file }: any) => {
      await uploadToContainer(
        onSuccess,
        onError,
        file,
        "bannerUrlImageDesktop",
      );
    },
  };

  const updateEntry = async () => {
    setSubmitting(true);
    const values = await entryForm.validateFields();

    await BannersAPI.updateBanner(values, values.bannerId)
      .then(() => {
        setShowModal(false);
        entryForm.resetFields();
        getBanners();
      })
      .catch(() => {
        message.error({
          content: `Ocurrió un error al actualizar el banner.`,
        });
        cancelModal();
      })
      .finally(() => {
        setSubmitting(false);
        setNewEntryImages({
          ...DEFAULTENTRYIMAGES,
        });
        setEditingBanner(false);
      });
  };

  const saveNewEntry = async () => {
    setSubmitting(true);
    const values = await entryForm.validateFields();

    if (!values.bannerUrlImageDesktopWebp || !values.bannerUrlImageMobileWebp) {
      message.error({
        content: `Por favor, agregue todas las imágenes formato webp.`,
      });
      setSubmitting(false);
      return;
    }
    if (!values.bannerUrlImageDesktop || !values.bannerUrlImageMobile) {
      message.error({
        content: `Por favor, agregue las imágenes en formato tradicional.`,
      });
      setSubmitting(false);
      return;
    }

    if (!values.bannerLinkRedirect) values.bannerLinkRedirect = "";
    values.bannerIndex = dataSource.length + 1;

    await BannersAPI.newBanner(values)
      .then(() => {
        setShowModal(false);
        entryForm.resetFields();
        setNewEntryImages({
          ...DEFAULTENTRYIMAGES,
        });
        getBanners();
      })
      .catch(() => {
        message.error({
          content: `Ocurrió un error al crear el banner.`,
        });
        cancelModal();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const tableColumns: ColumnsType = [
    {
      title: "Thumbnail escritorio webp",
      dataIndex: "bannerUrlImageDesktopWebp",
      key: "bannerUrlImageDesktopWebp",
      render: (desktopUrl: string) => {
        if (desktopUrl) {
          return (
            <Image
              style={{ width: "380px" }}
              className="desktop-img"
              src={`${desktopUrl}?${sasToken}`}
              preview
            />
          );
        } else {
          return "Sin foto";
        }
      },
    },
    {
      title: "Thumbnail móvil webp",
      dataIndex: "bannerUrlImageMobileWebp",
      key: "bannerUrlImageMobileWebp",
      render: (mobileUrl: string) => {
        if (mobileUrl) {
          return (
            <Image
              style={{ width: "180px" }}
              src={`${mobileUrl}?${sasToken}`}
              preview
            />
          );
        } else {
          return "Sin foto";
        }
      },
    },
    {
      title: "Thumbnail escritorio",
      dataIndex: "bannerUrlImageDesktop",
      key: "bannerUrlImageDesktop",
      render: (desktopUrl: string) => {
        if (desktopUrl) {
          return (
            <Image
              style={{ width: "380px" }}
              className="desktop-img"
              src={`${desktopUrl}?${sasToken}`}
              preview
            />
          );
        } else {
          return "Sin foto";
        }
      },
    },
    {
      title: "Thumbnail móvil",
      dataIndex: "bannerUrlImageMobile",
      key: "bannerUrlImageMobile",
      render: (mobileUrl: string) => {
        if (mobileUrl) {
          return (
            <Image
              style={{ width: "180px" }}
              src={`${mobileUrl}?${sasToken}`}
              preview
            />
          );
        } else {
          return "Sin foto";
        }
      },
    },
    {
      title: "Orden",
      dataIndex: "bannerIndex",
      key: "bannerIndex",
    },
    {
      title: "Link de redirección",
      dataIndex: "bannerLinkRedirect",
      key: "bannerLinkRedirect",
      render: (text: string) =>
        text ? (
          <a target="_blank" href={text}>
            {text}
          </a>
        ) : (
          "Ninguno"
        ),
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Dropdown
          placement="topRight"
          menu={{
            items: [
              ...(record.bannerIndex !== 1
                ? [
                    {
                      key: "1",
                      label: (
                        <Button
                          className="--custom"
                          icon={<UpOutlined />}
                          onClick={() => {
                            moveBannerTo(record.bannerIndex - 1, record);
                          }}
                        >
                          Mover 1 antes
                        </Button>
                      ),
                    },
                  ]
                : []),
              ...(record.bannerIndex !== dataSource.length
                ? [
                    {
                      key: "2",
                      label: (
                        <Button
                          className="--custom"
                          icon={<DownOutlined />}
                          onClick={() => {
                            moveBannerTo(record.bannerIndex + 1, record);
                          }}
                        >
                          Mover 1 después
                        </Button>
                      ),
                    },
                    ,
                  ]
                : []),
              {
                key: "3",
                label: (
                  <Button
                    className="--custom"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditingBanner(true);
                      setNewEntryImages({
                        bannerUrlImageDesktopWebp:
                          record.bannerUrlImageDesktopWebp,
                        bannerUrlImageMobileWebp:
                          record.bannerUrlImageMobileWebp,
                        bannerUrlImageDesktop: record.bannerUrlImageDesktop,
                        bannerUrlImageMobile: record.bannerUrlImageMobile,
                      });

                      entryForm.setFieldsValue({
                        bannerUrlImageDesktopWebp:
                          record.bannerUrlImageDesktopWebp,
                        bannerUrlImageMobileWebp:
                          record.bannerUrlImageMobileWebp,
                        bannerUrlImageDesktop: record.bannerUrlImageDesktop,
                        bannerUrlImageMobile: record.bannerUrlImageMobile,
                        bannerLinkRedirect: record.bannerLinkRedirect,
                        bannerId: record.bannerId,
                        bannerIndex: record.bannerIndex,
                      });

                      setShowModal(true);
                    }}
                  >
                    Editar link
                  </Button>
                ),
              },
              {
                key: "4",
                label: (
                  <Button
                    className="--custom --negative"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setBannerToDelete(record);
                    }}
                  >
                    Borrar
                  </Button>
                ),
              },
            ],
          }}
        >
          <Button
            className="--custom options-dropdown-btn"
            type="ghost"
            aria-label="Editar"
            icon={<EllipsisOutlined />}
            /* onClick={() => history.push(`BlogEntry/${elm.entryid}`)} */
          />
        </Dropdown>
      ),
    },
  ];

  const deletePictureFromContainer = async (url: string) => {
    try {
      const blockBlobClient = containerClient.getBlockBlobClient(url);
      await blockBlobClient.delete();
    } catch (error) {
      console.log(error);
    }
  };

  const deletePictures = async (deleteValues: any) => {
    const {
      bannerUrlImageDesktopWebp,
      bannerUrlImageDesktop,
      bannerUrlImageMobileWebp,
      bannerUrlImageMobile,
    } = deleteValues;

    if (bannerUrlImageDesktopWebp)
      deletePictureFromContainer(
        `banners/${bannerUrlImageDesktopWebp.split("/")[5]}`,
      );
    if (bannerUrlImageMobileWebp)
      deletePictureFromContainer(
        `banners/${bannerUrlImageMobileWebp.split("/")[5]}`,
      );

    if (bannerUrlImageDesktop)
      deletePictureFromContainer(
        `banners/${bannerUrlImageDesktop.split("/")[5]}`,
      );
    if (bannerUrlImageMobile)
      deletePictureFromContainer(
        `banners/${bannerUrlImageMobile.split("/")[5]}`,
      );
  };

  const deleteEntry = async (record: any) => {
    setLoading(true);
    const filteredData = dataSource.filter(
      (banner: any) => banner.bannerId !== record.bannerId,
    );
    let newIndex = 1;
    filteredData.forEach((banner: any) => {
      BannersAPI.updateBanner(
        {
          ...banner,
          bannerIndex: newIndex,
        },
        banner.bannerId,
      );
      newIndex++;
    });
    await deletePictures({
      bannerUrlImageDesktopWebp: record.bannerUrlImageDesktopWebp,
      bannerUrlImageMobileWebp: record.bannerUrlImageMobileWebp,
      bannerUrlImageDesktop: record.bannerUrlImageDesktop,
      bannerUrlImageMobile: record.bannerUrlImageMobile,
    });
    await BannersAPI.deleteBanner(record.bannerId)
      .then(() => {
        getBanners();
      })
      .catch(() => {
        message.error({
          content: `Ocurrió un error al borrar el banner.`,
        });
      })
      .finally(() => {
        setBannerToDelete(null);
        getBanners();
      });
  };

  const cancelModal = async () => {
    if (!editingBanner) {
      await deletePictures(newEntryImages);
    }
    entryForm.resetFields();
    setNewEntryImages({
      ...DEFAULTENTRYIMAGES,
    });
    setEditingBanner(false);
    setShowModal(false);
  };

  const moveBannerTo = async (newPosition: number, record: any) => {
    const filteredData = dataSource.filter(
      (banner: any) => banner.bannerId !== record.bannerId,
    );
    filteredData.splice(newPosition - 1, 0, record);

    let newIndex = 1;
    const newData: any[] = [];

    filteredData.forEach(async (banner: any) => {
      newData.push({
        ...banner,
        bannerIndex: newIndex,
      });
      newIndex++;
    });

    newData.forEach((banner: any) => {
      BannersAPI.updateBanner(
        {
          ...banner,
        },
        banner.bannerId,
      ).catch(() => {
        message.error({
          content: `Ocurrió un error al cambiar el orden.`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    });
    setDataSource(newData);
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  const getBanners = async () => {
    setLoading(true);
    const banners = await BannersAPI.getBanners();

    setDataSource(
      banners.data.sort((a: any, b: any) => a.bannerIndex - b.bannerIndex),
    );
    setLoading(false);
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <div className="banners-entries-explorer table-container">
      <div className="table-idenfity">
        <h2>Banners</h2>
        <div className="identify-options">
          <Button
            onClick={() => {
              getBanners();
            }}
            icon={<CkReloadIcon />}
            type="ghost"
            className="--custom"
          />
          <Button
            onClick={() => {
              if (dataSource.length >= 3) {
                message.error({
                  content: `Solo se permiten 3 banners.`,
                });
                return;
              }

              setShowModal(true);
            }}
            icon={<PlusOutlined />}
            className="--custom"
          >
            Nuevo banner
          </Button>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={tableColumns}
        loading={loading}
        bordered
        ref={containerRef}
        pagination={false}
        scroll={{
          x: true,
        }}
      />
      <ConfirmationModal
        visible={
          bannerToDelete !== undefined && bannerToDelete !== null && !loading
        }
        onOk={() => {
          deleteEntry(bannerToDelete);
        }}
        onCancel={() => {
          setBannerToDelete(null);
        }}
        onCloseIcon={() => {
          setBannerToDelete(null);
        }}
        title="¿Deseas borrar esta entrada?"
        message="Esta operación es permanente y los archivos asociados serán eliminados también."
        okText="Borrar"
        cancelText="Regresar"
        icon={<DeleteOutlined />}
        className="delete-post-confirm-modal"
      />
      <Modal
        open={showModal}
        className="add-entry-modal"
        closeIcon={<CKCloseIcon />}
        footer={null}
        onCancel={cancelModal}
      >
        <h2>Agregar banner:</h2>
        <Form form={entryForm}>
          <Form.Item name="bannerId" style={{ display: "none" }} />
          <Form.Item name="bannerIndex" style={{ display: "none" }} />
          <div>
            <div className="image-upload-warning-message">
              Formato optimizado WEBP
            </div>
            <Form.Item
              name="bannerUrlImageDesktopWebp"
              style={{ display: "none" }}
            />
            {!newEntryImages.bannerUrlImageDesktopWebp ? (
              <Upload {...uploadImagePropsDesktopWebp} accept="image/webp">
                <div className="image-upload">
                  {"Imagen de escritorio \n Dimensión sugerida: \n 1920x300"}
                </div>
              </Upload>
            ) : (
              <Image
                style={{ margin: "10px" }}
                src={`${newEntryImages.bannerUrlImageDesktopWebp}?${sasToken}`}
                preview={false}
              />
            )}
            <Form.Item
              name="bannerUrlImageMobileWebp"
              style={{ display: "none" }}
            />
            {!newEntryImages.bannerUrlImageMobileWebp ? (
              <Upload {...uploadImagePropsMobileWebp} accept="image/webp">
                <div className="image-upload">
                  {"Imagen de móvil \n Dimensión sugerida: \n 600x400"}
                </div>
              </Upload>
            ) : (
              <Image
                style={{ margin: "10px" }}
                src={`${newEntryImages.bannerUrlImageMobileWebp}?${sasToken}`}
                preview={false}
              />
            )}
          </div>
          <div>
            <div className="image-upload-warning-message">
              Formato tradicional JPEG/JPG/PNG
            </div>
            <Form.Item
              name="bannerUrlImageDesktop"
              style={{ display: "none" }}
            />
            {!newEntryImages.bannerUrlImageDesktop ? (
              <Upload
                {...uploadImagePropsDesktop}
                accept="image/png, image/jpeg, image/jpg"
              >
                <div className="image-upload">
                  {"Imagen de escritorio \n Dimensión sugerida: \n 1920x300"}
                </div>
              </Upload>
            ) : (
              <Image
                style={{ margin: "10px" }}
                src={`${newEntryImages.bannerUrlImageDesktop}?${sasToken}`}
                preview={false}
              />
            )}
            <Form.Item
              name="bannerUrlImageMobile"
              style={{ display: "none" }}
            />
            {!newEntryImages.bannerUrlImageMobile ? (
              <Upload
                {...uploadImagePropsMobile}
                accept="image/png, image/jpeg, image/jpg"
              >
                <div className="image-upload">
                  {"Imagen de móvil \n Dimensión sugerida: \n 600x400"}
                </div>
              </Upload>
            ) : (
              <Image
                style={{ margin: "10px" }}
                src={`${newEntryImages.bannerUrlImageMobile}?${sasToken}`}
                preview={false}
              />
            )}
          </div>
          <Form.Item extra="Link de redirección" name="bannerLinkRedirect">
            <Input className="--custom" />
          </Form.Item>
        </Form>
        <div className="modal-options">
          <Button className="cancel-btn" type="ghost" onClick={cancelModal}>
            Cancelar
          </Button>
          <Button
            className="ok-btn"
            onClick={() => {
              if (!editingBanner) saveNewEntry();
              else updateEntry();
            }}
            loading={submitting}
          >
            {!editingBanner ? "Agregar" : "Actualizar"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default BannersTable;
