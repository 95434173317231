import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ServiceByWorkshopType,
  Day,
  VehicleBrand,
  VehicleModel,
  AppointmentStatus,
  Service,
  Role,
  QuizSection,
  QuizChecklist,
} from '../data-models/Catalogs';
import * as api from '../services/catalogsAPI';
import { WorkshopType } from '../data-models/Workshop';

type CatalogState = {
  workshopTypes: WorkshopType[];
  ServicesByWorkshopType: Service[];
  Services: Service[];
  Roles: Role[];
  Days: Day[];
  sasToken: any;
  vehicleBrand: VehicleBrand[];
  vehicleModel: VehicleModel[];
  appointmentStatus: AppointmentStatus[];
  quizSections: QuizSection[];
  quizChecklist: QuizChecklist | null;
  loading: boolean;
  error: [boolean, string?];
};

let initialState: CatalogState = {
  workshopTypes: [],
  ServicesByWorkshopType: [],
  Services: [],
  Roles: [],
  Days: [],
  sasToken: null,
  vehicleBrand: [],
  vehicleModel: [],
  appointmentStatus: [],
  quizSections: [],
  quizChecklist: null,
  loading: false,
  error: [false, undefined],
};

export const fetchServices = createAsyncThunk(
  'catalogs/fetchServices',
  async (language: string) => {
    const response = await api.fetchServices(language);
    return response.data as unknown as Service[];
  },
);

export const fetchWorkshopTypes = createAsyncThunk(
  'catalogs/fetchWorkshopTypes',
  async (language: string) => {
    const response = await api.fetchWorkshopTypes(language);
    return response.data as unknown as WorkshopType[];
  },
);

export const fetchServicesByWorkshop = createAsyncThunk(
  'catalogs/fetchServiceByWorkshopType',
  async ({ language, workshopTypeId }: any) => {
    const response = await api.fetchServiceByWorkshopType(language, workshopTypeId);
    return response.data as unknown as Service[];
  },
);

export const fetchQuizSections = createAsyncThunk('catalogs/fetchQuizSections', async () => {
  const response = await api.fetchQuizSections();
  return response.data as unknown as QuizSection[];
});

export const fetchQuizChecklist = createAsyncThunk('catalogs/fetchQuizChecklist', async () => {
  const response = await api.fetchQuizChecklist();
  return response.data as unknown as QuizChecklist[];
});

export const fetchRoles = createAsyncThunk('catalogs/fetchRoles', async (language: string) => {
  const response = await api.fetchRoles(language);
  return response.data as unknown as Role[];
});

export const serviceSAS = createAsyncThunk('catalogs/serviceSas', async () => {
  const response = await api.fetchSASToken();
  return response.data as any;
});

export const fetchDays = createAsyncThunk('catalogs/days', async (language: string) => {
  const response = await api.fetchDays(language);
  return response.data as Day[];
});

export const fetchAppoitnmentStatus = createAsyncThunk(
  'catalogs/appointmentStatus',
  async (language: string) => {
    const response = await api.fetchAppointmentStatuses(language);
    return response.data;
  },
);

export const fetchVehicleBrands = createAsyncThunk('catalogs/vehicleBrands', async () => {
  const response = await api.fetchVehicleBrands();
  return response.data;
});

export const fetchVehicleModels = createAsyncThunk(
  'catalogs/vehicleModels',
  async ({ brandCode, year }: any) => {
    const response = await api.fetchVehicleModels(brandCode, year);
    return response.data;
  },
);

export const fetchDSRStatus = createAsyncThunk(
  'catalogs/workshopdsrstatus',
  async () => {
    const response = await api.fetchDSRStatus();
    return response.data;
  },
);

const catalogSlice = createSlice({
  name: 'catalogs',
  initialState: initialState,
  reducers: {
    clearVehicleModels(state) {
      state.vehicleModel = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
      state.Services = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchServices.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchServices.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir los servicios'];
    });

    builder.addCase(fetchServicesByWorkshop.fulfilled, (state, { payload }) => {
      state.ServicesByWorkshopType = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchServicesByWorkshop.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchServicesByWorkshop.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir los servicios'];
    });

    builder.addCase(fetchQuizSections.fulfilled, (state, { payload }) => {
      state.quizSections = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchQuizSections.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchQuizSections.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir el quiz'];
    });

    builder.addCase(fetchQuizChecklist.fulfilled, (state, { payload }) => {
      state.quizChecklist = payload[0];
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchQuizChecklist.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchQuizChecklist.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir el quiz'];
    });

    builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
      state.Roles = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchRoles.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchRoles.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir los roles'];
    });

    builder.addCase(serviceSAS.fulfilled, (state, { payload }) => {
      state.sasToken = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(serviceSAS.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(serviceSAS.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir el SAS TOKEN'];
    });

    builder.addCase(fetchDays.fulfilled, (state, { payload }) => {
      state.Days = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchDays.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchDays.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir los días'];
    });

    builder.addCase(fetchAppoitnmentStatus.fulfilled, (state, { payload }) => {
      let status: AppointmentStatus[] = [];
      status.push(payload.find((status) => status.code === 'PEND')!);
      status.push(payload.find((status) => status.code === 'APPO')!);
      status.push(payload.find((status) => status.code === 'RECEP')!);
      status.push(payload.find((status) => status.code === 'DIAG')!);
      status.push(payload.find((status) => status.code === 'PROG')!);
      status.push(payload.find((status) => status.code === 'READY')!);
      status.push(payload.find((status) => status.code === 'CLOS')!);
      status.push(payload.find((status) => status.code === 'CANCE')!);
      state.appointmentStatus = status;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(fetchAppoitnmentStatus.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchAppoitnmentStatus.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir los estatus de las citas'];
    });

    builder.addCase(fetchVehicleBrands.fulfilled, (state, { payload }) => {
      state.vehicleBrand = payload;
      //state.loading = false;
      //state.error = [false, undefined];
    });

    builder.addCase(fetchVehicleModels.fulfilled, (state, { payload }) => {
      state.vehicleModel = payload;
      //state.loading = false;
      //state.error = [false, undefined];
    });

    builder.addCase(fetchWorkshopTypes.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha habido un error al conseguir los tipos de taller'];
    });

    builder.addCase(fetchWorkshopTypes.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(fetchWorkshopTypes.fulfilled, (state, { payload }) => {
      state.workshopTypes = payload;
      state.loading = false;
      state.error = [false, undefined];
    });
  },
});
export const {} = catalogSlice.actions;

export const { clearVehicleModels } = catalogSlice.actions;
export default catalogSlice.reducer;
