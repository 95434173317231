import { AxiosResponse } from 'axios';
// import { ComunicationWorkshop } from '../data-models/Communication';
import axios from './axios';

export const getPackagesDetail = (
  workshopId: string,
  id: string | undefined,
): Promise<AxiosResponse<any>> => {
  return axios.get(`/servicepackages/detail`);
};

export const getWorkshopPackages = (
  workshopId: string,
  id: string | undefined,
): Promise<AxiosResponse<any>> => {
  return axios.get(`/servicepackages/${workshopId}`);
};

export const postWorkshopPackages = (
  workshopId: string,
  payload: any[],
): Promise<AxiosResponse<any>> => {
  return axios.post(`/servicepackages/${workshopId}`, payload);
};
