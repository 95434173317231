import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceCategory, ServiceType, WorkshopType } from "shared";
import { CatalogAPI } from "../../app/Services/axios";

type CatalogsState = {
  workshopTypes: WorkshopType[] | undefined;
  serviceType: ServiceType[];
  serviceCategories: ServiceCategory[];
  packageTypes: any[];
  status: "loading" | "succeeded" | "failed";
  error?: string;
};

let initialState: CatalogsState = {
  workshopTypes: [],
  serviceType: [],
  serviceCategories: [],
  packageTypes: [],
  status: "loading",
  error: undefined,
};

export const fetchPublicCatalogs = createAsyncThunk(
  "catalogs/fetchPublicCatalogs",
  async (language: string) => {
    const [
      typesResponse,
      serviceType,
      serviceCategoriesResponse,
      packageDetailResponse,
    ] = await Promise.all([
      CatalogAPI.getWorkshopTypes(language),
      CatalogAPI.getServiceType(language),
      CatalogAPI.getWorkshopServicesType(language),
      CatalogAPI.getPackagesDetail(),
    ] as const);

    return {
      types: typesResponse.data,
      serviceType: serviceType.data,
      serviceCategories: serviceCategoriesResponse.data,
      packageTypes: packageDetailResponse.data,
    };
  },
);

export const fetchWorkshopServicesType = createAsyncThunk(
  "catalogs/fetchWorkshopServicesType",
  async (payload: { language: string; serviceTypeCode: string[] }) => {
    const types = payload.serviceTypeCode.join();
    const serviceCategoriesResponse = await CatalogAPI.getWorkshopServicesType(
      payload.language,
      types,
    );
    return serviceCategoriesResponse.data;
  },
);

const PublicCatalogSlice = createSlice({
  name: "publicCatalog",
  initialState: initialState,
  reducers: {
    setWorkshopTypes(state, action) {
      state.workshopTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPublicCatalogs.fulfilled, (state, { payload }) => {
      const {
        types,
        serviceType,
        /*services,*/ serviceCategories,
        packageTypes,
      } = payload;
      state.workshopTypes = types;
      state.serviceType = serviceType;
      state.serviceCategories = serviceCategories.sort((a, b) => {
        if (a.serviceCategoryName > b.serviceCategoryName) return 1;
        if (a.serviceCategoryName < b.serviceCategoryName) return -1;
        return 0;
      });
      state.packageTypes = packageTypes;
      state.status = "succeeded";
      state.error = undefined;
    });

    builder.addCase(fetchPublicCatalogs.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined;
    });

    builder.addCase(fetchPublicCatalogs.rejected, (state, { payload }) => {
      state.status = "failed";
      state.error = "Failed to load catalogs";
    });

    builder.addCase(
      fetchWorkshopServicesType.fulfilled,
      (state, { payload }) => {
        state.serviceCategories = payload;
      },
    );
    builder.addCase(fetchWorkshopServicesType.pending, (state, { payload }) => {
      state.status = "loading";
      state.error = undefined;
    });

    builder.addCase(
      fetchWorkshopServicesType.rejected,
      (state, { payload }) => {
        state.status = "failed";
        state.error = "Failed to load catalogs";
      },
    );
  },
});

export const { setWorkshopTypes } = PublicCatalogSlice.actions;
export default PublicCatalogSlice.reducer;
