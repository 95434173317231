import { useEffect, useState, useRef } from "react";
import loadGoogleMaps from "./loadGoogleMaps";

const useLoadGoogleMaps = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (window.google !== undefined) setIsLoaded(true);
    if (window.google === undefined) {
      loadGoogleMaps();
      interval.current = setInterval(() => {
        if (window.google !== undefined) {
          setIsLoaded(true);
          interval.current && clearInterval(interval.current);
        }
      }, 500);
    }
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, []);

  return isLoaded;
};

export default useLoadGoogleMaps;
