import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/promoCodesAPI';
import { Balance, RedeemPetition, RedeemResponse, Reward } from '../data-models/PromoCodes';
import { NetworkError, getNetworkError } from '../services/networkUtils';

type PromoCodesState = {
  rewards: Reward[];
  balance: Balance | null;
  loading: boolean;
  action: [string, boolean];
  error: [boolean, string?];
};

let initialState: PromoCodesState = {
  rewards: [],
  balance: null,
  loading: false,
  action: ['', false],
  error: [false],
};

export const getRewards = createAsyncThunk('promoCodes/getRewards', async () => {
  const response = await api.getRewards();
  return response.data;
});

export const getUserBalance = createAsyncThunk(
  'promoCodes/getUserBalance',
  async (userId: number) => {
    const response = await api.getUserBalance(userId);
    return response.data;
  },
);

export const redeemReward = createAsyncThunk(
  'promoCodes/redeemReward',
  async (redeemPetition: RedeemPetition) => {
    const response = await api.redeemReward(redeemPetition);
    return response.data;
  },
);

export const redeemNotification = createAsyncThunk(
  'promoCodes/redeemNotification',
  async (redeem: { email: string; prizeRedeemedCode: string }) => {
    const response = await api.redeemNotification(redeem);
    return response.data;
  },
);

export const getInvitationCode = createAsyncThunk<
  string,
  { userReferrerId: number; userInvitedEmail: string },
  { rejectValue: NetworkError }
>(
  'promoCodes/getInvitationCode',
  async (invitation: { userReferrerId: number; userInvitedEmail: string }, thunkApi) => {
    try {
      const response = await api.getInvitationCode(invitation);
      return response.data;
    } catch (error) {
      if (error.response) {
        const networkError = getNetworkError(error);
        return thunkApi.rejectWithValue(networkError);
      }
      throw error;
    }
  },
);

export const inviteUser = createAsyncThunk(
  'promoCodes/inviteUser',
  async (invitation: { senderUserId: number; email: string }) => {
    const response = await api.inviteUser(invitation);
    return response.data;
  },
);

export const completeAppointment = createAsyncThunk(
  'promoCodes/completeAppointment',
  async (userEndconsumeEmail: string) => {
    const response = await api.completeAppointment(userEndconsumeEmail);
    return response.data;
  },
);

export const checkinNotification = createAsyncThunk(
  'promoCodes/checkinNotification',
  async (endConsumerUseEmail: string) => {
    const response = await api.checkinNotification(endConsumerUseEmail);
    return response.data;
  },
);

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRewards.fulfilled, (state, { payload }) => {
      state.rewards = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(getRewards.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];
      state.error = [false, undefined];
    });

    builder.addCase(getRewards.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(getUserBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(getUserBalance.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(getUserBalance.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(redeemReward.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(redeemReward.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];
      state.error = [false, undefined];
    });

    builder.addCase(redeemReward.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(redeemNotification.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(redeemNotification.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(redeemNotification.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(getInvitationCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(getInvitationCode.pending, (state, { payload }) => {
      state.action = ['pending', true];
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(getInvitationCode.rejected, (state, action) => {
      state.loading = false;
      state.error = [true, ''];
    });

    builder.addCase(inviteUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(inviteUser.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(inviteUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(completeAppointment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, ''];
    });

    builder.addCase(completeAppointment.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(completeAppointment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });

    builder.addCase(checkinNotification.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(checkinNotification.pending, (state, { payload }) => {
      state.loading = true;
      state.action = ['pending', true];

      state.error = [false, undefined];
    });

    builder.addCase(checkinNotification.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, undefined];
    });
  },
});

export const {} = promoCodesSlice;
export default promoCodesSlice.reducer;
