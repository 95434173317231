import { AxiosResponse } from 'axios';
import axios from './axios';
import { Workshop, EnrollmentQuiz } from '../data-models/Workshop';

export const fetchAll = () => {
  return axios.get('workshops');
};

export const add = (workshop: Workshop) => {
  return axios.post('workshops', workshop);
};

export const update = (workshop: Workshop) => {
  return axios.put(`workshops/${workshop.id}`, workshop);
};

export const erase = (workshopId: string | number) => {
  return axios.delete(`workshops/${workshopId}`);
};

export const submitQuiz = (quizData: EnrollmentQuiz) => {
  return axios.post(`workshops/${quizData.workshopID}/enrollmentOwnerQuiz`, quizData);
};
