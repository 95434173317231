import { combineReducers } from "redux";
import {
  workshopReducer,
  userReducer,
  catalogReducer,
  appointmentReducer,
  catalogsReducer,
} from "shared";

import { default as publicCatalogReducer } from "../Slice/PublicCatalogSlice";
import { default as WorkshopFilterSlice } from "../Slice/WorkshopFilterSlice";
import { default as NotificationSlice } from "../Slice/NotificationSlice";

const rootReducer = combineReducers({
  workshop: workshopReducer,
  user: userReducer,
  catalog: catalogReducer,
  appointment: appointmentReducer,
  catalogs: catalogsReducer,
  publicCatalog: publicCatalogReducer,
  workshopFiltered: WorkshopFilterSlice,
  notificationAppointment: NotificationSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
