import { RootState } from "../../stores";

export const getWorkshops = (state: RootState) => state.workshop.workshops;

export const getWorkshopTypes = (state: RootState) =>
  state.catalog.workshopTypes;

export const getVehicleBrands = (state: RootState) =>
  state.catalog.vehicleBrand;
export const getVehicleModels = (state: RootState) =>
  state.catalog.vehicleModel;

export const getLoading = (state: RootState) => state.workshop.loading;
export const getError = (state: RootState) => state.workshop.error;
export const getAction = (state: RootState) => state.workshop.action;

export const getUsers = (state: RootState) => state.user.users;
export const getUsersLoading = (state: RootState) => state.user.loading;
export const getUserError = (state: RootState) => state.user.error;
export const getUserAction = (state: RootState) => state.user.action;

export const getUser = (state: RootState) => state.user.user;

export const getServicesByWorkshopType = (state: RootState) =>
  state.catalog.ServicesByWorkshopType;

export const getServices = (state: RootState) => state.catalog.Services;
export const getRoles = (state: RootState) => state.catalog.Roles;

export const getLoadingCatalog = (state: RootState) => state.catalog.loading;
export const getErrorCatalog = (state: RootState) => state.catalog.error;
export const getDays = (state: RootState) => state.catalog.Days;

export const getSasToken = (state: RootState) => state.catalog.sasToken;

/*************************APPOINTMENTS***************************/

export const getAppointments = (state: RootState) =>
  state.appointment.appointments;
export const getAppointment = (state: RootState) =>
  state.appointment.appointment;
export const getAppointmentLoading = (state: RootState) =>
  state.appointment.loading;
export const getAppointmentError = (state: RootState) =>
  state.appointment.error;
export const getAppointmentStatus = (state: RootState) =>
  state.catalog.appointmentStatus;

/****************************PUBLIC CATALOGS************************************/

export const getPublicWorkshopType = (state: RootState) =>
  state.publicCatalog.workshopTypes;

export const getPublicServiceType = (state: RootState) =>
  state.publicCatalog.serviceType;

export const getPublicWorkshopServicesCategories = (state: RootState) =>
  state.publicCatalog.serviceCategories;

export const getPublicPackages = (state: RootState) =>
  state.publicCatalog.packageTypes;

/****************************PUBLIC WORKSHOP FILTERED************************************/

export const getWorkshopFiltered = (state: RootState) =>
  state.workshopFiltered.workshopsFiltered;

export const getCoordinates = (state: RootState) =>
  state.workshopFiltered.coordinates;

export const getLoadingWorshopFiltered = (state: RootState) =>
  state.workshopFiltered.status;

export const getQuizSections = (state: RootState) => state.catalog.quizSections;

export const getQuizChecklist = (state: RootState) =>
  state.catalog.quizChecklist;
//* **************************** PUBLIC APPOINTMENT NOTIFICATION********************* */
export const getNotificationAppointment = (state: RootState) =>
  state.notificationAppointment.Appoinments;

//* **************************** PUBLIC APPOINTMENT NOTIFICATION********************* */
export const getUserBalance = (state: RootState) =>
  state.notificationAppointment.balance;
