import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import * as packagesAPI from '../services/packagesAPI';

import { signOutUser } from './currentUserSlice';

type PackagesRequestsState = {
  packagesData: any[];
  packagesDataById: any | undefined;
  loading: boolean;
  action: [string, boolean];
  error: [boolean, string?];
};

let initialState: PackagesRequestsState = {
  packagesData: [],
  packagesDataById: undefined,
  loading: false,
  action: ['', false],
  error: [false, ''],
};

interface packagesI {
  data: any[];
}

export const getPackagesDetailsRequests = createAsyncThunk(
  'packagesRequests/getPackagesDetailsRequests',
  async ({ workshopId, id }: { workshopId: string; id?: string }) => {
    const response: AxiosResponse<packagesI[]> = await packagesAPI.getWorkshopPackages(
      workshopId,
      id,
    );
    return response;
  },
);

export const getPackagesRequests = createAsyncThunk(
  'packagesRequests/getPackagesRequests',
  async ({ workshopId, id }: { workshopId: string; id?: string }) => {
    const response: AxiosResponse<packagesI[]> = await packagesAPI.getPackagesDetail(
      workshopId,
      id,
    );
    return response;
  },
);

export const postWorkshopPackages = createAsyncThunk(
  'packagesRequests/postWorkshopPackages',
  async ({ workshopId, payload }: { workshopId: string; payload: any[] }) => {
    const response = await packagesAPI.postWorkshopPackages(workshopId, payload);
    return response.data;
  },
);

const PackagesRequestsSlice = createSlice({
  name: 'packagesRequests',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackagesDetailsRequests.fulfilled, (state, { payload }) => {
      state.packagesData = payload.data;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(getPackagesDetailsRequests.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(getPackagesDetailsRequests.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error.'];
    });

    // Get Request By Id
    builder.addCase(getPackagesRequests.fulfilled, (state, { payload }) => {
      state.packagesDataById = payload.data;
      state.loading = false;
      state.error = [false, undefined];
    });

    builder.addCase(getPackagesRequests.pending, (state, { payload }) => {
      state.loading = true;
      state.error = [false, undefined];
    });

    builder.addCase(getPackagesRequests.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = [true, 'Ha ocurrido un error.'];
    });

    builder.addCase(signOutUser, () => {
      return initialState;
    });
  },
});

export const {} = PackagesRequestsSlice.actions;
export default PackagesRequestsSlice.reducer;
