import { AxiosResponse } from 'axios';
import axios from './axios';
import {
  Day,
  Service,
  VehicleBrand,
  VehicleModel,
  ServiceCategory,
  AppointmentStatus,
  Role,
  VehicleType,
  VehicleBrandYear,
  VehicleFuelType,
  VehicleDisplacement,
  VehicleEquipment,
  DSRStatus,
} from '../data-models/Catalogs';
import { WorkshopType } from '../data-models/Workshop';

export const fetchServiceByWorkshopType = (language: string, workshopTypeCode: string) => {
  return axios.get<Service[]>(
    `catalogs/WorkshopTypeServices?language=${language}&workshopTypeCode=${workshopTypeCode}`,
  );
};

export const fetchServices = (language: string): Promise<AxiosResponse<Service[]>> => {
  return axios.get(`catalogs/services?language=${language}`);
};

export const fetchServiceCategories = (
  language: string,
): Promise<AxiosResponse<ServiceCategory[]>> => {
  return axios.get(`catalogs/serviceCategories?language=${language}`);
};

export const fetchSASToken = () => {
  return axios.get<string>('configurations/sasToken');
};

export const fetchDays = (language: string): Promise<AxiosResponse<Day[]>> => {
  return axios.get(`catalogs/days?language=${language}`);
};

export const fetchWorkshopTypes = (language: string): Promise<AxiosResponse<WorkshopType[]>> => {
  return axios.get(`catalogs/workshopTypes?language=${language}`);
};

export const fetchVehicleTypes = () => {
  return axios.get<VehicleType[]>('catalogs/vehicleTypes');
};

export const fetchVehicleBrands = (vehicleTypeCode: string) => {
  return axios.get<VehicleBrand[]>('catalogs/vehicleBrands', { params: { vehicleTypeCode } });
};

export const fetchVehicleBrandYears = (typeCode: string, brandCode: string) => {
  return axios.get<VehicleBrandYear[]>('catalogs/vehicleBrandYears', {
    params: { vehicleTypeCode: typeCode, vehicleBrandCode: brandCode },
  });
};

export const fetchVehicleModels = (typeCode: string, yearCode: string) => {
  return axios.get<VehicleModel[]>('catalogs/vehicleModels', {
    params: { vehicleTypeCode: typeCode, vehicleYearCode: yearCode },
  });
};

export const fetchVehicleFuelTypes = (typeCode: string, modelCode: string) => {
  return axios.get<VehicleFuelType[]>('catalogs/vehicleFuelTypes', {
    params: { vehicleTypeCode: typeCode, vehicleModelCode: modelCode },
  });
};

export const fetchVehicleDisplacements = (typeCode: string, fuelTypeCode: string) => {
  return axios.get<VehicleDisplacement[]>('catalogs/vehicleDisplacements', {
    params: { vehicleTypeCode: typeCode, vehicleFuelTypeCode: fuelTypeCode },
  });
};

export const fetchVehicleEquipments = (typeCode: string, displacementCode: string) => {
  return axios.get<VehicleEquipment[]>('catalogs/vehicleEquipments', {
    params: { vehicleTypeCode: typeCode, vehicleDisplacementCode: displacementCode },
  });
};

export const fetchAppointmentStatuses = (
  language: string,
): Promise<AxiosResponse<AppointmentStatus[]>> => {
  return axios.get(`catalogs/appointmentStatus?language=${language}`);
};

export const fetchRoles = (language: string): Promise<AxiosResponse<Role[]>> => {
  return axios.get(`catalogs/roles?language=${language}`);
};

export const fetchQuizSections = () => {
  return axios.get('catalogs/enrollmentQuizSections');
};

export const fetchQuizChecklist = () => {
  return axios.get('catalogs/quizChecklist');
};

export const fetchDSRStatus = () => {
  return axios.get<DSRStatus[]>('catalogs/workshopdsrstatus');
};
