import React from "react";
import { Modal, Button } from "antd";
import { CloseIcon } from "../../../assets/SvgIcons";

import "./ConfirmationModal.css";

interface IProps {
  icon?: JSX.Element;
  title?: string;
  message?: string | JSX.Element;
  subMessage?: string | JSX.Element;
  onOk: any;
  onCancel: any;
  visible: boolean;
  okText?: string;
  cancelText?: string;
  onCloseIcon?: Function;
  className?: string;
}

export const ConfirmationModal: React.FC<IProps> = ({
  icon,
  title,
  message,
  subMessage,
  onOk,
  onCancel,
  visible,
  okText,
  cancelText,
  onCloseIcon,
  className,
}) => {
  return (
    <Modal
      centered
      title={null}
      footer={null}
      visible={visible}
      onCancel={onCloseIcon ? onCloseIcon : onCancel}
      maskClosable={false}
      destroyOnClose={true}
      width={window.screen.width <= 992 ? "100%" : "812px"}
      closeIcon={<CloseIcon />}
      bodyStyle={{ padding: window.screen.width <= 992 ? "24px" : "50px" }}
      zIndex={3000}
      {...(className ? { className } : {})}
      className="confirmation-modal-container"
    >
      <div className="confirmation-modal-container-body">
        <div>{icon}</div>
        <div className="confirmation-modal-title">{title}</div>

        <div className="confirmation-modal-message">{message}</div>

        <div className="confirmation-modal-submessage">{subMessage}</div>

        <div className="confirmation-modal-buttons">
          <Button className="cancel-btn" onClick={onCancel}>
            {cancelText ? cancelText : "No"}
          </Button>
          <Button className="ok-btn" onClick={onOk}>
            {okText ? okText : "Sí"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
