import {
  Balance,
  PromoCodeFAQ,
  RedeemPetition,
  RedeemResponse,
  Reward,
} from '../data-models/PromoCodes';
import axios from './axios';
import * as PromoCodeFAQJson from './../../assets/PromoCodeFAQs.json';
import { AxiosResponse } from 'axios';
import { SignInAuthModule } from "../../../web/src/auth/SignInAuth";

const signInAuthModule: SignInAuthModule = new SignInAuthModule();



axios.interceptors.request.use(async (config) => {
  try {
    let response = await signInAuthModule.getProfileTokenRedirect();
    if (response) {
      config.headers.Authorization = `Bearer ${response}`;
    }
  } catch (e) {
    console.log(e);
  }
  return config;
});
export const getRewards = (): Promise<AxiosResponse<Reward[]>> => {
  return axios.get('promoCodes/rewards');
};

export const getUserBalance = (userId: number): Promise<AxiosResponse<Balance>> => {
  return axios.get(`promoCodes/balance/${userId}`);
};

export const redeemReward = (
  redeemPetition: RedeemPetition,
): Promise<AxiosResponse<RedeemResponse>> => {
  return axios.post(`promoCodes/redeem`, redeemPetition);
};

export const redeemNotification = (redeem: {
  email: string;
  prizeRedeemedCode: string;
}): Promise<AxiosResponse<boolean>> => {
  return axios.post(`promoCodes/redeemnotification`, redeem);
};

export const getInvitationCode = (invitation: {
  userReferrerId: number;
  userInvitedEmail: string;
}): Promise<AxiosResponse<string>> => {
  return axios.post(`promoCodes/code`, invitation);
};

export const inviteUser = (invitation: {
  senderUserId: number;
  email: string;
}): Promise<AxiosResponse<boolean>> => {
  return axios.post(`promoCodes/invitations`, invitation);
};

export const completeAppointment = (
  userEndconsumerEmail: string,
): Promise<AxiosResponse<string>> => {
  return axios.post(`/promoCodes/completeappointment`, { userEndconsumerEmail });
};

export const checkinNotification = (
  endConsumerUserEmail: string,
): Promise<AxiosResponse<boolean>> => {
  return axios.post(`/promoCodes/checkinnotification`, { endConsumerUserEmail });
};

export const getPromoCodeFAQs = (): Promise<PromoCodeFAQ[]> => {
  return new Promise((resolve, reject) => {
    const promoCodeFAQJson: PromoCodeFAQ[] = PromoCodeFAQJson.promoCodeFAQ;
    resolve(promoCodeFAQJson);
  });
};
