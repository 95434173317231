import React from "react";

import Logo from "../../assets/Images/logo-carker-gris.png";
import Logo2 from "../../assets/Images/logo-carker-gris@2x.png";

import { useWindowSize } from "../../app/Utilities/WindowSize";
import { useHistory } from "react-router-dom";

import "./MobilContigoStyle.css";

interface IProps {}

export const MobilcontigoFooter: React.FC<IProps> = () => {
  const history = useHistory();
  const windowSize = useWindowSize();
  const scrollToMap = () => {
    const element = document.getElementById("mapping");
    const elementAboutUs = document.getElementById("aboutUsContain");
    const elementCarKerRewards = document.getElementById(
      "carKerRewardsContain",
    );
    const elementContactUs = document.getElementById("contactUsContain");
    const elementTermsContain = document.getElementById("termsContain");
    const elementWorkshopCommitmentsContain = document.getElementById(
      "workshopCommitmentsContain",
    );

    if (element) element.scrollIntoView();
    if (elementAboutUs) elementAboutUs.scrollIntoView();
    if (elementCarKerRewards) elementCarKerRewards.scrollIntoView();
    if (elementContactUs) elementContactUs.scrollIntoView();
    if (elementTermsContain) elementTermsContain.scrollIntoView();
    if (elementWorkshopCommitmentsContain)
      elementWorkshopCommitmentsContain.scrollIntoView();
  };
  return (
    <div style={{ zIndex: 1 }}>
      <div
        className="footer"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "104px 11vw 103.3px 144px",
        }}
      >
        <div
          style={{
            width: windowSize.width > 685 ? "260px" : "180px",
          }}
        >
          <img
            src={Logo}
            srcSet={Logo2}
            alt="CarKer logo"
            width={windowSize.width > 685 ? "260px" : "180px"}
            height={windowSize.width > 685 ? "102px" : "71px"}
            object-fit="contain"
            onClick={() => {
              history.push("/");
              window.scrollTo({ top: 0 });
            }}
            style={{ cursor: "pointer", position: "relative" }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobilcontigoFooter;
