import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  Configuration,
  LogLevel,
  AccountInfo,
  InteractionRequiredAuthError,
  EndSessionRequest,
  RedirectRequest,
  PopupRequest,
  SsoSilentRequest,
} from "@azure/msal-browser";
import { message } from "antd";
import moment from "moment";

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
let MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID_SU as string,
    authority: "",
    knownAuthorities: [process.env.REACT_APP_KNOWAUTHORITY_SU as string],
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * AuthModule for application - handles authentication in app.
 */
export class SignInAuthModule {
  private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
  private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
  private loginRedirectRequest!: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
  // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
  private loginRequest!: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
  private profileRedirectRequest!: RedirectRequest;
  private profileRequest!: PopupRequest;
  private silentProfileRequest!: SilentRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_silentrequest_.html
  private silentLoginRequest!: SsoSilentRequest;

  constructor() {
    let authority = process.env.REACT_APP_SIGNIN_SU as string
    MSAL_CONFIG.auth!.authority = authority;
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
    this.setRequestObjects();

    // this.myMSALObj.addEventCallback(e => {
    //   if (e && e.payload) {
    //     if (e.eventType == "msal:loginSuccess") {
    //       var payload = e.payload as any;
    //       if (payload.idTokenClaims) {
    //         localStorage.setItem("tokenExpire", payload.idTokenClaims.exp);
    //       }
    //     } else if (e.eventType == "msal:logoutSuccess") {
    //       localStorage.removeItem("tokenExpire");
    //     }
    //   }

    // })

    // this.checkExpire(this.myMSALObj);
  }

  /**
   * Initialize request objects used by this AuthModule.
   */
  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: [process.env.REACT_APP_SCOPES_SU as string],
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };

    this.profileRequest = {
      scopes: [process.env.REACT_APP_SCOPES_SU as string],
    };

    this.profileRedirectRequest = {
      ...this.profileRequest,
      redirectStartPage: window.location.href,
    };

    this.silentLoginRequest = {
      authority: MSAL_CONFIG.auth?.authority,
      scopes: [process.env.REACT_APP_SCOPES_SU as string],
    };
  }

  private setProfileRequest(accountInfo: AccountInfo) {
    this.silentProfileRequest = {
      scopes: [process.env.REACT_APP_SCOPES_SU as string],
      account: accountInfo,
      forceRefresh: false,
    };
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private getAccount(): AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      //console.log("No accounts detected");
      return null;
    } else {
      //return currentAccounts[0];
      const currentDate = moment().utc();
      const filteredAccount = currentAccounts.filter(x => x.environment === process.env.REACT_APP_KNOWAUTHORITY_SU
                                                      && moment.unix(x.idTokenClaims?.exp).utc() > currentDate);
      if (filteredAccount === null || filteredAccount.length == 0){
        return null;
      }
      else {
        return (
          (process.env.REACT_APP_KNOWAUTHORITY_SU &&
            filteredAccount.find(
              (account) =>
                account.environment === process.env.REACT_APP_KNOWAUTHORITY_SU
            )) ||
          (MSAL_CONFIG.auth &&
            MSAL_CONFIG.auth.knownAuthorities &&
            filteredAccount.find(
              (account) =>
                account.environment === MSAL_CONFIG.auth.knownAuthorities![0]
            )) ||
            filteredAccount[0]
        );
      }
    }
  }

  isActiveAccount(): boolean {
    if (this.getAccount()) {
      return true;
    }
    return false;
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  loadAuthModule() {
    return this.myMSALObj.handleRedirectPromise();
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  handleResponse(response: AuthenticationResult | null) {
    if (response !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }
    return this.account;
  }

  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  login(signInType: "loginPopup" | "loginRedirect"): void {
    if (signInType === "loginPopup") {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          this.handleResponse(resp);
        })
        .catch(console.error);
    } else if (signInType === "loginRedirect") {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest).catch((e) => {
        if (e.errorCode === "interaction_in_progress") {
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(";");
          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
              c = c.substring(1);
            }
            if (c.includes("interaction.status")) {
              document.cookie = `${c.split("=")[0]
                }=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
              if (
                window.confirm(
                  "Algo ha salido mal, ¿Deseas intentar nuevamente?"
                )
              ) {
                this.login("loginRedirect");
              } else {
                message.error("Algo ha salido mal, intenta nuevamente.");
              }
            }
          }
        }
      });
    }
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    const logOutRequest: EndSessionRequest = {
      account: this.account!,
    };

    this.myMSALObj.logout(logOutRequest);
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(): Promise<string | null | undefined> {
    this.handleResponse(null);
    this.setProfileRequest(this.account!);
    return this.getTokenRedirect(
      this.silentProfileRequest,
      this.profileRedirectRequest
    );
  }

  attemptSsoSilent() {
    this.myMSALObj
      .ssoSilent(this.silentLoginRequest)
      .then(() => {
        this.account = this.getAccount();
      })
      .catch((error) => { });
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null | undefined> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      //console.log("silent token acquisition fails.");
      if (e instanceof InteractionRequiredAuthError) {
        //console.log("acquiring token using redirect");
        this.myMSALObj
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
      } else {
        //console.error(e);
      }
    }
  }

  private checkExpire(msal: PublicClientApplication): void {
    var time: any, interval: any;

    window.onload = resetTimer;
    // document.onmousemove = resetTimer;
    // document.onkeypress = resetTimer;

    function startStuff() {
      interval = setInterval(() => {
        let tokenExpire = localStorage.getItem('tokenExpire');
        if (tokenExpire) {
          let addMinute = 0; // increase for shorten test
          let now = new Date((new Date().getTime() + addMinute * 60000)) as any;
          let expireTime = new Date(parseInt(tokenExpire) * 1000) as any;
          let diffInMin = Math.round((((expireTime - now) % 86400000) % 3600000) / 60000);
          //console.log(`>> Token will expire in ${diffInMin} min`)
          if (now > expireTime || diffInMin <= 0) {
            localStorage.removeItem('tokenExpire');
            //console.log("   >>>> Token Expired Force Logout <<<<")
            msal.logout();
            return
          }
        }
      }, 60000);
    }

    function resetTimer() {
      clearTimeout(time);
      clearInterval(interval);
      time = setTimeout(startStuff)
    }
  }

}
