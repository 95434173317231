import Layout from "antd/lib/layout/layout";
import React, { useEffect, useState, FC } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { User } from "shared";
import { UserAPI } from "./app/Services/axios";
import { CurrentUser } from "./context/UserContext";
import loadable from "@loadable/component";
import {
  SuperUserNavBar,
  SuperUserSubNavBar,
} from "./Screens/MobilContigo LandingPage/SuperUserNavBar";
import SasTokenRefresher from "./app/Components/SasTokenRefresher";
import MobilContigoFooter from "./Screens/MobilContigo LandingPage/MobilcontigoFooter";
import { WheelLoader } from "./app/Components/Loaders";
import { SignInAuthModule } from "./auth/SignInAuth";
import { loadGoogleTagManager } from "./app/Utilities";
import BannersUpdate from "./Screens/MobilContigo LandingPage/BannersUpdate";

const NotFoundPage = loadable(() => import("./Screens/NotFoundPage"), {
  fallback: <WheelLoader />,
});

const SuperUserNotLoggedIn = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperUserNotLoggedIn"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.SuperUserNotLoggedIn,
  },
);
const Dashboard = loadable(
  () => import("./Screens/MobilContigo LandingPage/Dashboard"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.Dashboard,
  },
);
const CreateSU = loadable(
  () => import("./Screens/MobilContigo LandingPage/CreateSU"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.CreateSU,
  },
);
const SuperServicesManagement = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperServicesManagement"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.SuperServicesManagement,
  },
);
const SuperWorkshop = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperWorkshop"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperEndConsumerTable = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperEndConsumerTable"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperWorkshopModification = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperWorkshopModification"),
  {
    fallback: <WheelLoader />,
  },
);
const BlogDashboard = loadable(
  () => import("./Screens/MobilContigo LandingPage/BlogDashboard"),
  {
    fallback: <WheelLoader />,
  },
);
const BlogEntry = loadable(
  () => import("./Screens/MobilContigo LandingPage/BlogEntry"),
  {
    fallback: <WheelLoader />,
  },
);
const BlogEntryPreview = loadable(
  () => import("./Screens/MobilContigo LandingPage/BlogEntryPreview"),
  {
    fallback: <WheelLoader />,
  },
);
const AppointmentDetail = loadable(
  () => import("./Screens/MobilContigo LandingPage/AppointmentDetail"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperDiagnostic = loadable(
  () => import("./Screens/MobilContigo LandingPage/DiagnosticList/index"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperQuotation = loadable(
  () => import("./Screens/MobilContigo LandingPage/QuotationList/index"),
  {
    fallback: <WheelLoader />,
  },
);
const DiagnosticForm = loadable(
  () => import("./Screens/MobilContigo LandingPage/DiagnosticForm/index"),
  {
    fallback: <WheelLoader />,
  },
);
const QuotationForm = loadable(
  () => import("./Screens/MobilContigo LandingPage/QuotationForm/index"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperCommunication = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperCommunication/index"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.SuperCommunication,
  },
);
const SuperBlacklist = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperBlacklist/index"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.SuperBlacklist,
  },
);
const SuperBlacklistHistory = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperBlacklistHistory"),
  {
    fallback: <WheelLoader />,
  },
);
const ModifComment = loadable(
  () => import("./Screens/MobilContigo LandingPage/ModifComment"),
  {
    fallback: <WheelLoader />,
  },
);
const ModifUser = loadable(
  () => import("./Screens/MobilContigo LandingPage/ModifUser"),
  {
    fallback: <WheelLoader />,
  },
);
const ConfigurePassword = loadable(
  () => import("./app/Components/Form/ConfigurePassword"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperLogs = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperLogs"),
  {
    fallback: <WheelLoader />,
  },
);
const SuperRatings = loadable(
  () => import("./Screens/MobilContigo LandingPage/SuperRatings"),
  {
    fallback: <WheelLoader />,
  },
);
const Reports = loadable(
  () => import("./Screens/MobilContigo LandingPage/Reports"),
  {
    fallback: <WheelLoader />,
  },
);
const AccountSettings = loadable(
  () => import("./Screens/Dashboard/AccountSettings"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.AccountSettings,
  },
);
const ConfigSuperUsers = loadable(
  () => import("./Screens/MobilContigo LandingPage/ConfigSuperUsers"),
  {
    fallback: <WheelLoader />,
  },
);
const ModifSuperUser = loadable(
  () => import("./Screens/MobilContigo LandingPage/ModifSuperUser"),
  {
    fallback: <WheelLoader />,
  },
);
const WorkshopList = loadable(
  () => import("./Screens/MobilContigo LandingPage/WorkshopList"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.WorkshopList,
  },
);
const WorkshopHighlight = loadable(
  () => import("./Screens/MobilContigo LandingPage/WorkshopHighlight"),
  {
    fallback: <WheelLoader />,
    resolveComponent: (components) => components.WorkshopHighlight,
  },
);
const ReceptionForm = loadable(
  () => import("./Screens/MobilContigo LandingPage/ReceptionForm"),
  {
    fallback: <WheelLoader />,
  },
);

const signInAuthModule: SignInAuthModule = new SignInAuthModule();

export const AppSuperUser: FC = () => {
  const history = useHistory();
  const [user, setUser] = useState<User>();
  const [authError, setAuthError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const gtmInit = () => {
    loadGoogleTagManager();
    window.removeEventListener("click", gtmInit);
  };

  const logUser = async () => {
    signInAuthModule
      .loadAuthModule()
      .then((resp: any) => {
        signInAuthModule.handleResponse(resp);
        if (resp) {
          localStorage.setItem("e", resp.account.idTokenClaims.emails[0]);
          if (resp.account.idTokenClaims.extension_MustResetPassword) {
            Promise.resolve()
              .then(() => {
                history.push("/resetPassword");
              })
              .finally(() => {
                window.location.reload();
              });
          } else {
            UserAPI.me()
              .then((values) => {
                setUser(values.data);
              })
              .finally(() => {
                setLoading(false);
                let state = localStorage.getItem("redirect");
                if (state) {
                  let ws = JSON.parse(state);
                  if (ws) {
                    localStorage.removeItem("redirect");
                    history.push({ pathname: ws.route, state: ws.display });
                  }
                }
              });
          }
        } else {
          if (signInAuthModule.isActiveAccount()) {
            UserAPI.me()
              .then((values) => {
                setUser(values.data);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        if (error.errorMessage.indexOf("AADB2C90118") > -1) {
          setAuthError(true);
        } else if (error.errorMessage.indexOf("AADB2C90006") > -1) {
          history.replace("/");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    logUser();
    return () => {
      window.removeEventListener("click", gtmInit);
    };
  }, []);

  useEffect(() => {
    loading === false && window.addEventListener("click", gtmInit);
  }, [loading]);

  if (loading) return <WheelLoader mode="initial-loader" />;

  if (authError)
    return (
      <Switch>
        <Route exact path="/WorkshopMap">
          <Redirect to="/reseteoContraseña" />
        </Route>
        <Redirect to={"/"} />
      </Switch>
    );

  return (
    <CurrentUser.Provider value={user}>
      <Layout>
        <SuperUserNavBar
          loading={loading}
          logout={() => {
            signInAuthModule.logout();
          }}
          login={() => {
            signInAuthModule.login("loginRedirect");
          }}
        />
        {user && (
          <>
            <SuperUserSubNavBar />
            <SasTokenRefresher />
          </>
        )}
        <main className="page-container">
          {!user && (
            <Switch>
              <Route exact path="/">
                <SuperUserNotLoggedIn
                  login={() => {
                    signInAuthModule.login("loginRedirect");
                  }}
                />
              </Route>
              <Redirect to={"/"} />
            </Switch>
          )}
          {user && (
            <Switch>
              <Route exact path="/SuperWorkshop/:id">
                <SuperWorkshop />
              </Route>
              <Route exact path="/SuperEndConsumerTable/:id/:email">
                <SuperEndConsumerTable />
              </Route>
              <Route exact path="/SuperWorkshopModification/:id">
                <SuperWorkshopModification />
              </Route>
              <Route exact path="/ModifComment">
                <ModifComment />
              </Route>
              <Route exact path="/ModifUser/:id/:email">
                <ModifUser />
              </Route>
              <Route exact path="/CreateSU">
                <CreateSU />
              </Route>
              <Route exact path="/SuperQuotation">
                <SuperQuotation />
              </Route>
              <Route exact path="/SuperDiagnostic">
                <SuperDiagnostic />
              </Route>
              <Route exact path="/SuperDiagnostic/:id">
                <DiagnosticForm />
              </Route>
              <Route exact path="/SuperQuotation/:id">
                <QuotationForm />
              </Route>
              <Route exact path="/SuperCommunication">
                <SuperCommunication />
              </Route>
              <Route exact path="/SuperBlacklist">
                <SuperBlacklist />
              </Route>
              <Route exact path="/SuperBlacklistHistory/:id">
                <SuperBlacklistHistory />
              </Route>
              <Route exact path="/History">
                <SuperLogs />
              </Route>
              <Route exact path="/SuperRatings">
                <SuperRatings />
              </Route>
              <Route exact path="/Reports">
                <Reports />
              </Route>
              <Route exact path="/configuracion">
                <AccountSettings />
              </Route>
              <Route exact path="/configurarUsuarios">
                <ConfigSuperUsers user={user} />
              </Route>
              <Route exact path="/BuscarTaller">
                <WorkshopList />
              </Route>
              <Route exact path="/ModifSuperUser/:id">
                <ModifSuperUser />
              </Route>
              <Route exact path="/AppointmentDetail/:id">
                <AppointmentDetail />
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/SuperEndConsumer">
                <SuperServicesManagement />
              </Route>
              <Route exact path="/BlogDashboard">
                <BlogDashboard />
              </Route>
              <Route exact path="/BlogEntry/:id">
                <BlogEntry />
              </Route>
              <Route exact path="/BlogEntryPreview/:id">
                <BlogEntryPreview />
              </Route>
              <Route exact path="/talleres-destacados">
                <WorkshopHighlight />
              </Route>
              <Route exact path="/banners">
                <BannersUpdate />
              </Route>
              <Route exact path="/resetPassword">
                <ConfigurePassword
                  userEmail={localStorage.getItem("e")}
                  logout={() => {
                    signInAuthModule.handleResponse(null);
                    signInAuthModule.logout();
                  }}
                  redirectDashboard={false}
                />
              </Route>
              <Route exact path="/formulario-recepcion/:id">
                <ReceptionForm />
              </Route>
              <Route path="/404">
                <NotFoundPage />
              </Route>
              <Redirect to={"/404"} />
            </Switch>
          )}
        </main>
        <MobilContigoFooter />
      </Layout>
    </CurrentUser.Provider>
  );
};
