import axios from './axios';
import { User } from '../data-models/User';
import { UserNotification } from '../data-models/User';

export const fetchAll = () => {
  return axios.get('users');
};

export const fetchAllCoworkers = (workshopId: string) => {
  return axios.get(`users?workshopid=${workshopId}`);
};

export const fetchUserByAzureId = (azureId: string) => {
  return axios.get(`users?azureCode=${azureId}`);
};

export const fetchUser = () => {
  return axios.get<User>('users/me');
};

export const add = (user: User) => {
  return axios.post('users', user);
};

export const addCoworker = (user: User) => {
  return axios.post<User>('users?addToAD=true', user);
};

export const update = (user: User) => {
  return axios.put(`users/${user.id}`, user);
};

export const erase = (userId: string | number) => {
  return axios.delete(`users/${userId}`);
};

export const me = () => {
  return axios.get(`users/me`);
};

export const updatePassword = (password: string) => {
  return axios.put('/b2c/users/me', { password: password });
};

export const notificaton = (notification: UserNotification) => {
  return axios.post('/notifications', notification);
};
