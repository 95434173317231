import { AxiosResponse } from 'axios';
import axios from './axios';
import { Appointment } from '../data-models/Appointment';

export const fetchAppointmentsByWorkshop = (
  workshopId: string,
  initialDate?: string,
  finalDate?: string,
): Promise<AxiosResponse<Appointment[]>> => {
  return axios.get(
    `/workshops/${workshopId}/appointments?DateFrom=${initialDate ? initialDate : ''}&DateTo=${
      finalDate ? finalDate : ''
    }`,
  );
};

export const fetchAppointmentById = (
  appointmentId: string,
  workshopId: string,
): Promise<AxiosResponse<Appointment>> => {
  return axios.get(`/workshops/${workshopId}/appointments/${appointmentId}`);
};

export const fetchAppointmentByStatus = (
  workshopId: string,
  status: string,
): Promise<AxiosResponse<Appointment[]>> => {
  return axios.get(`/workshops/${workshopId}/appointments/?statusCode=${status}`);
};

export const add = (
  appointment: Appointment,
  workshopId: string,
): Promise<AxiosResponse<Appointment>> => {
  return axios.post(`/workshops/${workshopId}/appointments`, appointment);
};

export const update = (
  appointment: Appointment,
  workshopId: string,
): Promise<AxiosResponse<Appointment>> => {
  return axios.put(`/workshops/${workshopId}/appointments/${appointment.id}`, appointment);
};

export const erase = (
  appointmentId: string,
  workshopId: string,
): Promise<AxiosResponse<number>> => {
  return axios.delete(`/workshops/${workshopId}/appointments/${appointmentId}`);
};
