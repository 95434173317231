import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Service,
  Day,
  ServiceCategory,
  AppointmentStatus,
  VehicleType,
} from '../data-models/Catalogs';
import { WorkshopType } from '../data-models/Workshop';
import * as catalogsAPI from '../services/catalogsAPI';
import { signOutUser } from './currentUserSlice';
import { Reward } from '../data-models/PromoCodes';
import * as promoCodeAPI from '../services/promoCodesAPI';
import { PromoCodeFAQ } from '../data-models/PromoCodes';

type CatalogsState = {
  workshopTypes: WorkshopType[];
  days: Day[];
  services: Service[];
  serviceCategories: ServiceCategory[];
  appointmentStatuses: AppointmentStatus[];
  suggestedServices: Service[];
  promoCodeFAQs: PromoCodeFAQ[];
  rewards: Reward[];
  vehicleTypes: VehicleType[];
  status: 'loading' | 'succeeded' | 'failed';
  error?: string;
};

let initialState: CatalogsState = {
  workshopTypes: [],
  days: [],
  services: [],
  serviceCategories: [],
  appointmentStatuses: [],
  suggestedServices: [],
  promoCodeFAQs: [],
  rewards: [],
  vehicleTypes: [],
  status: 'loading',
  error: undefined,
};

export const fetchCatalogs = createAsyncThunk(
  'catalogs/fetchCatalogs',
  async (language: string) => {
    const [
      typesResponse,
      daysResponse,
      servicesResponse,
      categoriesResponse,
      appointmentStatusesResponse,
      promoCodeFAQs,
      rewards,
      vehicleTypesResponse,
    ] = await Promise.all([
      catalogsAPI.fetchWorkshopTypes(language),
      catalogsAPI.fetchDays(language),
      catalogsAPI.fetchServices(language),
      catalogsAPI.fetchServiceCategories(language),
      catalogsAPI.fetchAppointmentStatuses(language),
      promoCodeAPI.getPromoCodeFAQs(),
      promoCodeAPI.getRewards(),
      catalogsAPI.fetchVehicleTypes(),
    ] as const);

    return {
      types: typesResponse.data,
      days: daysResponse.data,
      services: servicesResponse.data,
      categories: categoriesResponse.data,
      appointmentStatuses: appointmentStatusesResponse.data,
      promoCodeFAQs: promoCodeFAQs,
      rewards: rewards.data,
      vehicleTypes: vehicleTypesResponse.data,
    };
  },
);

export const fetchSuggestedServices = createAsyncThunk(
  'catalogs/fetchSuggestedServices',
  async (args: { workshopTypeCode: string; language: string }) => {
    let response = await catalogsAPI.fetchServiceByWorkshopType(
      args.language,
      args.workshopTypeCode,
    );
    return response.data;
  },
);

const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCatalogs.fulfilled, (state, { payload }) => {
      const {
        types,
        days,
        services,
        categories,
        appointmentStatuses,
        promoCodeFAQs,
        rewards,
        vehicleTypes,
      } = payload;
      state.workshopTypes = types;
      state.days = days;
      state.services = services;
      state.serviceCategories = categories;
      state.appointmentStatuses = sortedAppointmentStatuses(appointmentStatuses);
      state.promoCodeFAQs = promoCodeFAQs;
      state.rewards = rewards;
      state.vehicleTypes = vehicleTypes;
      state.status = 'succeeded';
      state.error = undefined;
    });

    builder.addCase(fetchCatalogs.pending, (state, { payload }) => {
      state.status = 'loading';
      state.error = undefined;
    });

    builder.addCase(fetchCatalogs.rejected, (state, { payload }) => {
      state.status = 'failed';
      state.error = 'Failed to load catalogs';
    });

    builder.addCase(fetchSuggestedServices.pending, (state, { payload }) => {
      state.suggestedServices = [];
    });
    builder.addCase(fetchSuggestedServices.fulfilled, (state, { payload }) => {
      state.suggestedServices = payload;
    });
    builder.addCase(signOutUser, () => {
      return initialState;
    });
  },
});

export default catalogsSlice.reducer;

const sortedAppointmentStatuses = (statuses: AppointmentStatus[]): AppointmentStatus[] => {
  const order = ['APPO', 'RECEP', 'DIAG', 'PROG', 'READY', 'CLOS', 'CANCE'];
  return statuses.sort((a, b) => {
    return order.indexOf(a.code) - order.indexOf(b.code);
  });
};
