import React from "react";
import BannersTable from "./BannersTable";
import "./styles.css";

const BannersUpdate = () => {
  return (
    <section className="banners-dashboard">
      <h1>Actualizar imágenes del banner</h1>
      <BannersTable />
      <div className="tables-grid"></div>
    </section>
  );
};

export default BannersUpdate;
