import React, { FC } from "react";
import { Spin } from "antd";
import { WheelIcon2 } from "../../../../assets/SvgIcons";
import "./style.css";

interface IProps {
  spinning: boolean;
  className?: string;
  size?: "small" | "medium" | "large";
  mode?: "cover" | "inline"
  children?: any;
}
const WheelSpinner: FC<IProps> = ({
  spinning = false,
  size= "medium",
  mode = "cover",
  className,
  children
}) =>  (
  <Spin
    indicator={<WheelIcon2 className="wheel-icon" />}
    spinning={spinning}
    wrapperClassName="wheel-spinner"
    className={[
      ...(!!children === false ? ["wheel-spinner-inline"] : [] ),
      ...(size ? [size] : []),
      ...(className ? [className] : [])
    ].join(' ')}
  >
    {children && children}
  </Spin>
)

export default WheelSpinner;
