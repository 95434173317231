import Axios, { AxiosResponse } from "axios";
import querystring from "querystring";
import {
  Vehicle,
  EndConsumerFeedback,
  RatingDetail,
} from "shared/src/data-models/EndConsumer";
import {
  IPFilterWorkshop,
  WorkshopPublicDetail,
  WorkshopPublicBasicInfo,
  IRecommendedServicesParams,
} from "shared/src/data-models/WorkshopPublic";
import {
  VehicleLicensePlateState,
  VehicleMainPurpose,
  RecommendedServices,
  Product,
  ServiceCategory,
  VehicleCatalog,
} from "shared/src/data-models/Catalogs";
import { WorkshopService, WorkshopType } from "shared/src/data-models/Workshop";
import {
  DashboardInc,
  DashboardTable,
} from "shared/src/data-models/DashboardSU";
import { User } from "shared/src/data-models/User";
import { Appointment, AppointmentWorkshop } from "shared";
import { SignInAuthModule } from "../../auth/SignInAuth";
import {
  Balance,
  RedeemPetition,
  RedeemResponse,
  Reward,
} from "../models/Promocodes";
import { SuperUser } from "../models/user";
import {
  IBlogEntry,
  IBlogCategory,
  IBlogTag,
  IBlogAuthor,
  TEntryStatus,
  TEntryAudience,
} from "../models/blog";
const signInAuthModule: SignInAuthModule = new SignInAuthModule();

const filteredParams = (params: object) => {
  return Object.entries(params)
    .filter(([key, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

const urlify = (params: object) => querystring.stringify(params);

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});
axios.interceptors.request.use(async (config) => {
  try {
    let response = await signInAuthModule.getProfileTokenRedirect();
    if (response) {
      config.headers.Authorization = `Bearer ${response}`;
    }
  } catch (e) {
    console.log(e);
  }
  return config;
});
const requests = {
  get: (url: string, params?: any) => axios.get(url, { params }),
  post: (url: string, body: {}) => axios.post(url, body),
  put: (url: string, body: {}) => axios.put(url, body),
  del: (url: string) => axios.delete(url),
  patch: (url: string, body: {}) => axios.patch(url, body),
};
export const WorkshopAPI = {
  getById: (
    id: number,
    params: { coordinates: string; userTimeZone: string },
  ): Promise<AxiosResponse<WorkshopPublicDetail>> =>
    requests.get(`/publicInfo/workshops/${id}`, params),
  get: (
    filters?: IPFilterWorkshop,
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops`, filters),
  search: (
    filters?: IPFilterWorkshop,
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops/search`, filters),
  searchNearby: (
    filters: any,
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops/searchNearby`, filters),
  getNavigationUrl: (workshopId: number): Promise<AxiosResponse<string>> =>
    requests.get(`/getNavigationUrl/${workshopId}`),
  resetSurvey: (id: number): Promise<AxiosResponse<boolean>> =>
    requests.get(`workshop/${id}/resetWorkshop`),
  getByPackage: (filters: any): Promise<AxiosResponse<any[]>> =>
    requests.get(`/publicInfo/workshops/servicepackage/search`, filters),
  getCustomServices: (workshopId: any): Promise<AxiosResponse<any[]>> =>
    requests.get(`/workshop/${workshopId}/CustomService`),
  searchDuplicates: (
    workshopEmail?: string,
    workshopName?: string,
    sucursalName?: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(
      `/workshops/searchduplicates?${
        workshopEmail !== undefined ? `workshopEmail=${workshopEmail}` : ""
      }${workshopName !== undefined ? `workshopName=${workshopName}` : ""}
        ${sucursalName !== undefined ? `&sucursalName=${sucursalName}` : ""}`,
    ),
  getServicesTypes: (id: number): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/workshop/${id}/servicesTypes`),
  getCarouselServices: (): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/catalogs/serviceTypesCarousel?language=ES-MX`),
  getWorkshopCategories: async (
    workshopId: string | number,
  ): Promise<AxiosResponse<any>> =>
    requests.get(`workshop/${workshopId}/serviceTypes`),
  getMigrationHistory: (workshopId: string): Promise<AxiosResponse<any[]>> =>
    requests.get(`/workshops/migrateWorshopOwner/${workshopId}`),
  getSurvey: (workshopId: number): Promise<AxiosResponse<any>> =>
    requests.get(`workshops/${workshopId}/enrollmentOwnerQuiz`),
  getAll: (
    payload: any,
    WorkshopAdditionalInfo?: string[],
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(
      `workshops/all?${
        WorkshopAdditionalInfo
          ? `WorkshopAdditionalInfo=${WorkshopAdditionalInfo}`
          : ""
      }`,
      payload,
    ),
  getWorkshopAverageResponseTime: (
    workshopId: number,
  ): Promise<AxiosResponse<string>> =>
    requests.get(`/publicInfo/workshops/responseTime/${workshopId}`),
  areCoordinatesAllowed: (
    workshopId: number,
    coordinates: string,
  ): Promise<AxiosResponse<boolean>> =>
    requests.get(
      `/workshops/coordinatesAllowed/${workshopId}?coordinates=${coordinates}`,
    ),
  getCoworkersByWorkshop: async (payload: {
    workshopId: string;
    azureCode: string | null;
  }): Promise<AxiosResponse<any>> => requests.get(`/users`, payload),
  getServicesByWorkshop: async (payload: {
    workshopId: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(`/workshop/${payload.workshopId}/services`),
  getPackagesByWorkshop: async (payload: {
    workshopId: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(`/workshop/${payload.workshopId}/packages`),
  updateServices: async (
    id: string,
    payload: any[],
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/workshop/${id}/serviceTypes`, payload),
  addCustomService: async (
    workshopId: string,
    payload: any[],
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/workshop/${workshopId}/CustomService`, payload),
  updateCustomService: async (
    workshopId: string,
    id: string,
    payload: any,
  ): Promise<AxiosResponse<any>> =>
    requests.put(`/workshop/${workshopId}/CustomService/${id}`, payload),
  deleteCustomService: async (
    workshopId: string,
    id: string,
  ): Promise<AxiosResponse<any>> =>
    requests.del(`/workshop/${workshopId}/CustomService/${id}`),
  toggleHighlight: async (
    id: string,
    value: boolean,
  ): Promise<AxiosResponse<any>> =>
    requests.put(`/workshop/${id}/highlight?highlight=${value}`, {}),
};
export const VehicleAPI = {
  get: (): Promise<AxiosResponse<Vehicle[]>> => requests.get("/vehicles"),
  getEndConsumerVehicles: (id: string): Promise<AxiosResponse<Vehicle[]>> =>
    requests.get(`/vehicles?endConsumerUserId=${id}`),
  getById: (id: string): Promise<AxiosResponse<Vehicle>> =>
    requests.get(`/vehicles/${id}`),
  add: (vehicle: Vehicle): Promise<AxiosResponse<Vehicle>> =>
    requests.post("/vehicles", vehicle),
  update: (
    vehicle: Vehicle,
    oldLicensePlate: string,
  ): Promise<AxiosResponse<Vehicle>> =>
    requests.put(`/vehicles/${oldLicensePlate}`, vehicle),
  delete: (id: string) => requests.del(`/vehicles/${id}`),
  setIsMain: (
    id: string,
    isMainVehicle: boolean,
  ): Promise<AxiosResponse<Vehicle>> =>
    requests.patch(`/vehicles/${id}`, [
      {
        path: "/IsMainVehicle",
        op: "replace",
        value: isMainVehicle,
      },
    ]),
};

export const CatalogAPI = {
  fetchVehicleTypes: async (): Promise<AxiosResponse<any[]>> =>
    requests.get("catalogs/vehicleTypes"),
  getVehicleMainPurposes: async (
    language: string,
  ): Promise<AxiosResponse<VehicleMainPurpose[]>> =>
    requests.get(`/catalogs/vehicleMainPurposes?language=${language}`),
  getVehicleLicensePlateStates: async (
    language: string,
  ): Promise<AxiosResponse<VehicleLicensePlateState[]>> =>
    requests.get(`/catalogs/vehicleLicensePlateStates?language=${language}`),
  getWorkshopTypes: async (
    language: string = "ES-MX",
  ): Promise<AxiosResponse<WorkshopType[]>> =>
    requests.get(`/catalogs/workshopTypes?language=${language}`),
  getWorkshopServicesType: async (
    language: string = "ES-MX",
    serviceTypeCode?: string,
  ): Promise<AxiosResponse<ServiceCategory[]>> =>
    requests.get(
      `/catalogs/serviceCategories?language=${language}${
        serviceTypeCode ? "&serviceTypeCodes=" + serviceTypeCode : ""
      }`,
    ),
  fetchVehicleBrands: async (
    vehicleTypeCode: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get("catalogs/vehicleBrands", {
      vehicleTypeCode: vehicleTypeCode,
    }),
  getServices: async (
    language: string = "ES-MX",
  ): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/catalogs/services?language=${language}`),
  getDays: (language: string = "ES-MX"): Promise<AxiosResponse<[]>> =>
    requests.get(`/catalogs/days?language=${language}`),
  getServiceType: async (
    language: string = "ES-MX",
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/serviceTypes?language=${language}`),
  getServiceTypeCarousel: async (
    language: string = "ES-MX",
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/serviceTypesCarousel?language=${language}`),
  getPackagesInfo: async (): Promise<AxiosResponse<any[]>> =>
    requests.get(`servicepackages/detail`),
  getPackages: async (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/servicepackages`),
  getWorkshopPackages: async (
    workshopId: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/servicepackages/${workshopId}`),
  getPackagesDetail: async (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/servicepackages`),
  getRatingDetails: (
    language: string,
  ): Promise<AxiosResponse<RatingDetail[]>> =>
    requests.get(`/catalogs/endConsumerFeedbackCategory?language=${language}`),
  getRecommendedServices: async (
    params: IRecommendedServicesParams,
  ): Promise<AxiosResponse<RecommendedServices[]>> =>
    requests.get(`catalogs/serviceRecommendations`, params),
  getRecommendedProducts: async (
    vehicleLicensePlate: string,
    serviceCodeList: string,
    userId?: string | undefined,
  ): Promise<AxiosResponse<Product[]>> =>
    requests.get(
      `catalogs/productRecommendations?vehicleLicensePlate=${vehicleLicensePlate}&serviceCodeList=${serviceCodeList}&userId=${userId}`,
    ),
  getVehicleTypes: async (
    language: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(`/catalogs/vehicleTypes?language=${language}`),
  getVehicleBrands: async (
    language: string,
    vehicleTypeCode: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(`/catalogs/vehicleBrands?vehicleTypeCode=${vehicleTypeCode}`),
  getVehicleBrandYears: async (
    language: string,
    vehicleTypeCode: string,
    vehicleBrandCode: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(
      `/catalogs/vehicleBrandYears?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleBrandCode=${vehicleBrandCode}`,
    ),
  getVehicleModels: async (
    language: string,
    vehicleTypeCode: string,
    vehicleYearCode: string,
    vehicleBrandCode?: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(
      `/catalogs/vehicleModels?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleYearCode=${vehicleYearCode}${
        vehicleBrandCode ? `&vehicleBrandCode=${vehicleBrandCode}` : ""
      }`,
    ),
  getVehicleFuelTypes: async (
    language: string,
    vehicleTypeCode: string,
    vehicleModelCode: string,
    vehicleBrandCode?: string,
    vehicleYearCode?: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(
      `/catalogs/vehicleFuelTypes?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleModelCode=${vehicleModelCode}${
        vehicleBrandCode ? `&vehicleBrandCode=${vehicleBrandCode}` : ""
      }${vehicleYearCode ? `&vehicleYearCode=${vehicleYearCode}` : ""}`,
    ),
  getVehicleDisplacements: async (
    language: string,
    vehicleTypeCode: string,
    vehicleFuelTypeCode: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(
      `/catalogs/vehicleDisplacements?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleFuelTypeCode=${vehicleFuelTypeCode}`,
    ),
  getVehicleEquipments: async (
    language: string,
    vehicleTypeCode: string,
    vehicleDisplacementCode: string,
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    requests.get(
      `/catalogs/vehicleEquipments?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleDisplacementCode=${vehicleDisplacementCode}`,
    ),
  getVehicleAccesories: async (
    language: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/vehicleAccesories?${language}`),
  getLocationInformation: (
    latitude: string,
    longitude: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/catalogs/locationInfo?latitude=${latitude}&longitude=${longitude}`,
    ),
  getServiceZones: (): Promise<AxiosResponse<any>> =>
    requests.get(`/catalogs/vehicleZones`),
};
export const RatingAPI = {
  addByAppointment: (
    rating: EndConsumerFeedback[],
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/endConsumerFeedback`, {
      EndConsumerFeedbacks: rating,
    }),
  deleteComment: (id: number): Promise<AxiosResponse<any>> =>
    requests.del(`/endConsumerFeedback/Messages/${id}/DeleteReported`),
  preservComment: (id: number): Promise<AxiosResponse<any>> =>
    requests.del(`/endConsumerFeedback/Messages/${id}/RemoveReported`),
  getNotifications: (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/appointments`, { needFeedback: true }),
  getComments: (
    page: number,
    pageSize: number,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(
      `/endConsumerFeedback/Messages/Reported?page=${page}&pageSize=${pageSize}`,
    ),
  getCategoryReports: (): Promise<AxiosResponse<any>> =>
    requests.get(`/catalogs/EndConsumerFeedbackReportReason`),
};
export const UserAPI = {
  me: async (): Promise<AxiosResponse<User>> => requests.get("users/me"),
  update: async (user: User): Promise<AxiosResponse<User>> =>
    requests.put(`users/${user.id}`, user),
  updatePassword: async (password: string) =>
    requests.put("/b2c/users/me?isEndConsumer=true", { password: password }),
  delete: async (id: string, fullDelete: boolean, reason: string) =>
    requests.del(`users/${id}?fullDelete=${fullDelete}&deleteReason=${reason}`),
  checkUser: async (email: string) =>
    requests.get("users/isB2CUser", { email: email }),
  addCoworker: async (payload: any) =>
    requests.post("/users?addToAD=true", payload),
};
export const ConfigurationsAPI = {
  getSasToken: (): Promise<AxiosResponse<string>> =>
    requests.get("configurations/sasToken"),
};

export const QuotationAPI = {
  updateQuotation: async (payload: any): Promise<AxiosResponse<any>> =>
    requests.put(`/Quotation`, payload),
};

export const AppointmentAPI = {
  get: async (
    startDateTime: string,
    endDateTime: string,
    workshopId?: number,
  ): Promise<AxiosResponse<Appointment>> =>
    requests.get(
      `/workshopappointments?dateFrom=${startDateTime}&dateTo=${endDateTime}${
        workshopId ? "&workshopId=" + workshopId : ""
      }`,
    ),
  getByAppId: async (
    appId?: number,
  ): Promise<AxiosResponse<AppointmentWorkshop>> =>
    requests.get(`/workshops/appointments/${appId}`),
  getEC: async (
    startDateTime: string,
    endDateTime: string,
    userId?: number,
  ): Promise<AxiosResponse<Appointment>> =>
    requests.get(
      `/userappointments?dateFrom=${startDateTime}&dateTo=${endDateTime}${
        userId ? "&userId=" + userId : ""
      }`,
    ),
  add: async (
    appointment: Appointment,
    workshopId: string,
    isReschedule?: boolean,
    coordinates?: string,
  ): Promise<AxiosResponse<Appointment>> =>
    requests.post(
      `/workshops/${workshopId}/appointments${
        isReschedule ? "?isReschedule=true" : ""
      }?coordinates=${coordinates ?? ""}`,
      appointment,
    ),
  addNonRegistered: async (
    appointment: Appointment,
    workshopId: string,
    isReschedule?: boolean,
  ): Promise<AxiosResponse<Appointment>> =>
    requests.post(
      `/workshops/${workshopId}/appointmentsNonRegistered${
        isReschedule ? "?isReschedule=true" : ""
      }`,
      appointment,
    ),
  update: async (
    appointment: Appointment,
    workshopId: string,
    isReschedule?: boolean,
  ): Promise<AxiosResponse<Appointment>> =>
    requests.put(
      `/workshops/${workshopId}/appointments/${appointment.id}${
        isReschedule ? "?isReschedule=true" : ""
      }`,
      appointment,
    ),
  confirm: async (id: string, code: string): Promise<AxiosResponse<any>> =>
    requests.put(
      `/workshops/appointmentsNonRegistered/${id}/?validationCode=${code}`,
      "",
    ),
  addComment: async (
    comment: string,
    workshopId?: string,
    userId?: string,
    workshopAppointmentId?: string,
  ): Promise<AxiosResponse<boolean>> =>
    requests.post(
      `/comments?comment=${comment}&workshopId=${workshopId}&userId=${userId}&workshopAppointmentId=${workshopAppointmentId}`,
      "",
    ),
  migrate: async (id: string, newWS: string): Promise<AxiosResponse<any>> =>
    requests.post(`/migrateWSAppointment?id=${id}&newWS=${newWS}`, ""),
  getTrackingReception: async (id: string): Promise<AxiosResponse<any>> =>
    requests.get(`/appointmentTrackingReception/${id}`),
  getAppById: async (appId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/workshops/appointments/${appId}`),
  getLastAppointment: async (userId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/lastAppointment/${userId}`),
  updateDiagnosticServices: async (
    payload: any[],
    appointmentId: string,
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/appointmentTrackingService/${appointmentId}`, payload),
  updateDiagnosticPackages: async (
    payload: any[],
    appointmentId: string,
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/appointmentTrackingPackageSet/${appointmentId}`, payload),
};
export const DashboardAPI = {
  getMetrics: async (
    dateFrom: string,
    dateTo: string,
  ): Promise<AxiosResponse<DashboardInc>> =>
    requests.get(
      `/workshops/metrics/appointments?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    ),
  getUserMetrics: async (
    dateFrom: string,
    dateTo: string,
  ): Promise<AxiosResponse<DashboardInc>> =>
    requests.get(
      `/user/metrics/appointments?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    ),
  getAppointmentsMetrics: async (
    dateFrom: string,
    dateTo: string,
    status: string,
  ): Promise<AxiosResponse<DashboardTable>> =>
    requests.get(
      `/workshops/metrics/filterAppointmentsMetrics?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}`,
    ),
  getUserAppointmentsMetrics: async (
    dateFrom: string,
    dateTo: string,
    status: string,
  ): Promise<AxiosResponse<DashboardTable>> =>
    requests.get(
      `/user/metrics/filterAppointmentsMetrics?dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}`,
    ),
  getMetricsWorkshop: async (
    dateFrom: string,
    dateTo: string,
    workshopId: number,
  ): Promise<AxiosResponse<DashboardInc>> =>
    requests.get(
      `/workshops/metrics/appointments?dateFrom=${dateFrom}&dateTo=${dateTo}&workshopId=${workshopId}`,
    ),
  getMetricsUser: async (
    dateFrom: string,
    dateTo: string,
    userId: number,
  ): Promise<AxiosResponse<DashboardInc>> =>
    requests.get(
      `/user/metrics/appointments?dateFrom=${dateFrom}&dateTo=${dateTo}&userId=${userId}`,
    ),
  getUserByMail: async (
    email: string,
    name: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/users/search?email=${email}&name=${name}`),
  getWorkshopByMail: async (
    email: string,
    name: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/workshops/search?workshopEmail=${email}&workshopName=${name}`,
    ),
  restorePassword: async (
    payload: any,
    sendEmail: any,
    sendSMS: any,
  ): Promise<AxiosResponse<any>> =>
    requests.put(
      `/b2c/users/password?sendEmail=${sendEmail}&sendSMS=${sendSMS}`,
      payload,
    ),
  migrateUser: async (payload: any): Promise<AxiosResponse<any>> =>
    requests.post(`/workshops/migrateWorshopOwner`, payload),
  getUsersNoRole: async (): Promise<AxiosResponse<any>> =>
    requests.get(`/wsUsersNoRole`),
  getPageRange: async (payload: {
    pageFrom: string | number;
    pageTo: string | number;
    dateFrom: string;
    dateTo: string;
    status?: string;
    name?: string;
    wsName?: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(
      `EndConsumerRequest/metrics/FilterRequestsMetrics?${
        "page=" + payload.pageFrom
      }${payload.pageTo ? "&pageTo=" + payload.pageTo : ""}${
        payload.dateTo ? "&dateTo=" + payload.dateTo : ""
      }${payload.dateFrom ? "&dateFrom=" + payload.dateFrom : ""}${
        payload.name ? "&name=" + payload.name : ""
      }${payload.wsName ? "&wsName=" + payload.wsName : ""}${
        payload.status ? "&status=" + payload.status : ""
      }`,
    ),
  getRequests: async (payload: {
    dateFrom: string;
    dateTo: string;
    status?: string;
    page?: string | number;
    name?: string;
    wsName?: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(
      `EndConsumerRequest/metrics/FilterRequestsMetrics?${
        "page=" + payload.page
      }${payload.dateTo ? "&dateTo=" + payload.dateTo : ""}${
        payload.dateFrom ? "&dateFrom=" + payload.dateFrom : ""
      }${payload.name ? "&name=" + payload.name : ""}${
        payload.wsName ? "&wsName=" + payload.wsName : ""
      }${payload.status ? "&status=" + payload.status : ""}`,
    ),
  getQuotationList: async (payload: {
    page: string | number;
    pageSize: string | number;
    userId?: string | number | undefined | null;
    workshopId?: string | number | undefined | null;
    workshopName?: string | undefined | null;
    appointmentDate?: string | undefined | null;
    sortType?: string | null;
    sortField?: string | null;
  }): Promise<AxiosResponse<any>> =>
    requests.get(`Quotation?${urlify(filteredParams(payload))}`),
  getDiagnosticList: async (payload: {
    page: string | number;
    pageSize: string | number;
    userId?: string | number | undefined | null;
    workshopId?: string | number | undefined | null;
    workshopName?: string | undefined | null;
    appointmentDate?: string | undefined | null;
    sortType?: string | null;
    sortField?: string | null;
  }): Promise<AxiosResponse<any>> =>
    requests.get(`Diagnostic?${urlify(filteredParams(payload))}`),
  getMessages: async (requestId: string): Promise<AxiosResponse<any>> =>
    requests.get(`WorkshopRequestMessages/?RequestId=${requestId}`),
  getPhotosToMessages: async (
    wsId: string,
    userId: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(`chatProfilePhotos/${wsId}/user/${userId}`),
};

export const SUAPI = {
  add: async (superUser: SuperUser): Promise<AxiosResponse<SuperUser>> =>
    requests.post(`/superusers`, superUser),
  get: async (): Promise<AxiosResponse<any>> => requests.get(`/superusers`),
  getById: async (userId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/superusers/${userId}`),
  getLog: async (payload: {
    dateFrom: string;
    dateTo: string;
    superUser?: string;
    user?: string;
    workshop?: string;
    actionTypeList?: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(`superusers/log/?${
      payload.superUser ? "&superUser=" + payload.superUser : ""
    }
    ${payload.user ? "&user=" + payload.user : ""}
    ${payload.workshop ? "&workshop=" + payload.workshop : ""}
    ${payload.actionTypeList ? "&actionTypeList=" + payload.actionTypeList : ""}
    &DateFrom=${payload.dateFrom}&DateTo=${payload.dateTo}`),
};

export const PromoCodesAPI = {
  getRewards: async (): Promise<AxiosResponse<Reward[]>> =>
    requests.get("/promoCodes/rewards"),
  getUserBalance: async (userId: number): Promise<AxiosResponse<Balance>> =>
    requests.get(`/promoCodes/balance/${userId}`),
  redeemReward: async (
    redeem: RedeemPetition,
  ): Promise<AxiosResponse<RedeemResponse>> =>
    requests.post("/promoCodes/redeem", redeem),
  redeemNotification: async (redeem: {
    email: string;
    prizeRedeemedCode: string;
  }): Promise<AxiosResponse<boolean>> =>
    requests.post("/promoCodes/redeemnotification", redeem),
  getInvitationCode: async (invitation: {
    userReferrerId: number;
    userInvitedEmail: string;
  }): Promise<AxiosResponse<string>> =>
    requests.post("/promoCodes/code", invitation),
  inviteUser: async (invitation: {
    senderUserId: number;
    email: string;
  }): Promise<AxiosResponse<string>> =>
    requests.post("/promoCodes/invitations", invitation),
  checkUserInvite: async (user: {
    userInvitedID: number;
    userInvitedEmail: string;
  }): Promise<AxiosResponse<string>> =>
    requests.put("/promoCodes/usersignup", user),
  reception: async (code: string): Promise<AxiosResponse<any>> =>
    requests.put(`QRCodes/${code}`, ""),
};

export const NotificationAPI = {
  migrationNotification: async (payload: any): Promise<AxiosResponse<any>> =>
    requests.post(`/notifications/migrationnotification`, payload),
};

export const CommunicationAPI = {};

export const FeedbackAPI = {
  getWorkshopRatingSummary: async (
    workshopId: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/endConsumerFeedback/WorkshopRatingSummary/${workshopId}`),
  getWorkshopRating: async (
    workshopId: string,
    page?: number,
    pageSize?: number,
    status?: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/endConsumerFeedback/WorkshopRating/${workshopId}?page=${
        page ? page : 1
      }&pageSize=${pageSize ? pageSize : 0}${
        status !== undefined ? `&status=${status}` : ""
      }`,
    ),
  getWorkshopRatingVisible: async (
    workshopId: string,
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/endConsumerFeedback/WorkshopRatingVisible/${workshopId}`),
  getWorkshopsWithRating: async (
    sortDesc?: boolean,
    sortCriteria?: string,
  ): Promise<AxiosResponse<any[]>> => {
    const params: any = {};
    if (sortDesc !== undefined) params["sortDesc"] = sortDesc;
    if (sortCriteria !== undefined) params["sortCriteria"] = sortCriteria;

    return requests.get(`/endConsumerFeedback/WorkshopsWithRating`, params);
  },
  hideMessage: async (messageId: string): Promise<AxiosResponse<any>> =>
    requests.put(`/endConsumerFeedback/HideMessages/${messageId}`, ""),
  publishMessage: async (messageId: string): Promise<AxiosResponse<any>> =>
    requests.put(`/endConsumerFeedback/ShowMessages/${messageId}`, ""),
  reportUser: async (
    userId: string,
    comment: string,
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/userClaims/reportUser`, {
      UserReportedId: userId,
      Comment: comment,
    }),
  reportComment: async (
    id: string,
    comment: string,
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/userClaims/reportFeedbackMessage`, {
      EndConsumerFeedbackMessageId: id,
      Comment: comment,
    }),
};

export const BlacklistAPI = {
  getWorkshopsBlacklisted: async (
    payload?: any,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/workshops/blacklisted`, payload),
  getWorkshopCategories: async (
    language: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/workshopcategory?language=${language}`),
  getWorkshopdsrStatus: async (
    language: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/workshopdsrstatus?language=${language}`),
  getWorkshopdsrStatusReason: async (
    language: string,
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/workshopdsrstatusreason?language=${language}`),
  modifyWorkshopStatus: async (
    workshopId: number,
    payload: { statusCode: string; comment: string; reasonId?: number },
  ): Promise<AxiosResponse<any>> =>
    requests.put(`workshops/${workshopId}/dsrStatus`, payload),
  modifyWorkshopCategory: async (payload: {
    workshopId: string;
    comment: string;
    categoryId: number;
  }): Promise<AxiosResponse<any>> =>
    requests.put(`workshops/workshopCategory`, payload),
  getWorkshopsBlacklistHistory: async (
    id: string,
  ): Promise<AxiosResponse<{ workshop: any; statusHistory: any[] }>> =>
    requests.get(`/workshops/${id}/dsrStatusHistory`),
};

export const BannersAPI = {
  getBanners: async (): Promise<AxiosResponse> => {
    return requests.get("/catalogs/banners");
  },
  newBanner: async (payload: any) => {
    return requests.post("/catalogs/banners", payload);
  },
  deleteBanner: async (id: number) => {
    return requests.del(`/catalogs/banners/${id}`);
  },
  updateBanner: async (payload: any, id: number) => {
    return requests.put(`/catalogs/banners/${id}`, payload);
  },
};

export const BlogAPI = {
  getPosts: async ({
    page = 0,
    pageSize = 15,
    audience /*  = "ALL" */,
    statusCodes = ["visible"],
    showContent = false,
    EntryIdOffset,
    pinnedContent,
    urlCategory,
    urlTag,
    urlAuthor,
  }: {
    page: number;
    pageSize: number;
    statusCodes: TEntryStatus[];
    showContent: boolean; // if is set to false, contentFragments will not be loaded
    audience?: TEntryAudience;
    EntryIdOffset?: number;
    pinnedContent?: boolean;
    urlCategory?: string;
    urlTag?: string;
    urlAuthor?: string;
  }): Promise<
    AxiosResponse<{
      entries: IBlogEntry[];
      pages: number;
      totalEntries: number;
    }>
  > => {
    const payload = {
      page,
      pageSize,
      audience,
      statusCodes: statusCodes.join(","),
      showContent,
      ...(audience ? { audience } : {}),
      ...(EntryIdOffset ? { EntryIdOffset } : {}),
      ...(pinnedContent ? { pinnedContent } : {}),
    };
    if (urlCategory)
      return requests.get(`blog/category/${urlCategory}/entries`, payload);
    if (urlTag) return requests.get(`blog/tag/${urlTag}/entries`, payload);
    if (urlAuthor)
      return requests.get(`blog/author/${urlAuthor}/entries`, payload);
    return requests.get(`/blog/entry`, payload);
  },
  getPostBy: async ({
    id,
    urlTitle,
  }: {
    id?: number;
    urlTitle?: string;
  }): Promise<AxiosResponse<IBlogEntry>> =>
    requests.get(`/blog/entry/${id || urlTitle}`),
  setPost: async (payload: IBlogEntry): Promise<AxiosResponse<IBlogEntry>> => {
    if (typeof payload.contentFragments !== "string")
      payload.contentFragments = JSON.stringify(payload.contentFragments);
    return requests.post(`/blog/entry`, payload);
  },
  updatePost: async ({
    id,
    payload,
  }: {
    id: number;
    payload: IBlogEntry;
  }): Promise<AxiosResponse<IBlogEntry>> => {
    if (typeof payload.contentFragments !== "string")
      payload.contentFragments = JSON.stringify(payload.contentFragments);
    return requests.put(`/blog/entry/${id}`, payload);
  },
  deletePost: async (id: number): Promise<AxiosResponse<IBlogEntry[]>> =>
    requests.del(`/blog/entry/${id}`),
  getTags: async (): Promise<AxiosResponse<IBlogTag[]>> =>
    requests.get(`/blog/tag`),
  getTagBy: async ({
    id,
    urlName,
  }: {
    id?: number;
    urlName?: string;
  }): Promise<AxiosResponse<IBlogTag>> =>
    requests.get(`/blog/tag/${id || urlName}`),
  setTag: async (payload: {
    tagName: string;
    urlName: string;
  }): Promise<AxiosResponse<IBlogTag>> => requests.post(`/blog/tag`, payload),
  updateTag: async ({
    id,
    payload,
  }: {
    id: number;
    payload: {
      tagName: string;
      urlName: string;
    };
  }): Promise<AxiosResponse<IBlogTag>> =>
    requests.put(`/blog/tag/${id}`, payload),

  getAuthors: async (): Promise<AxiosResponse<IBlogAuthor[]>> =>
    requests.get(`/blog/author`),
  getAuthorBy: async (id: number): Promise<AxiosResponse<IBlogAuthor>> =>
    requests.get(`/blog/author/${id}`),
  setAuthor: async (payload: {
    authorName: string;
    urlName: string;
  }): Promise<AxiosResponse<IBlogAuthor>> =>
    requests.post(`/blog/author`, payload),
  updateAuthor: async ({
    id,
    payload,
  }: {
    id: number;
    payload: {
      authorName: string;
      urlName: string;
    };
  }): Promise<AxiosResponse<IBlogAuthor>> =>
    requests.put(`/blog/author/${id}`, payload),

  getCategories: async (): Promise<AxiosResponse<IBlogCategory[]>> =>
    requests.get(`/blog/category`),
  getCategoryBy: async (id: number): Promise<AxiosResponse<IBlogCategory>> =>
    requests.get(`/blog/category/${id}`),
  setCategory: async (payload: {
    categoryName: string;
    urlName: string;
  }): Promise<AxiosResponse<IBlogCategory>> =>
    requests.post(`/blog/category`, payload),
  updateCategory: async ({
    id,
    payload,
  }: {
    id: number;
    payload: {
      categoryName: string;
      urlName: string;
    };
  }): Promise<AxiosResponse<IBlogCategory>> =>
    requests.put(`/blog/category/${id}`, payload),
};
